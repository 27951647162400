import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const ZapIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.success[500];

  return <path d="M0 7.5L3.75 0V4.5H6L2.25 12V7.5H0Z" fill={fill} />;
};

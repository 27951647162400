import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const LeftArrowIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        d="M15.375 21.284L6.09099 12L15.375 2.71599L15.375 21.284Z"
        fill={fill}
        stroke={fill}
        strokeWidth="2.25"
      />
    </>
  );
};

import { useEffect, useState } from "react";
import {
  Col,
  HorizontalAlignment,
  Row,
  Spacing,
  TimeInput,
  VerticalAlignment,
} from "../../../components";
import { Typography } from "../../../components/Typography";
import { TextColor } from "../../../util";
import { DepartureTimes } from "../types/common";

interface Props {
  disabled: boolean;
  departureTimes?: DepartureTimes;
  onDepartureTimesChange: (value: DepartureTimes | undefined) => void;
}

export const DeadlinesForDaysOfWeek = ({
  disabled,
  departureTimes,
  onDepartureTimesChange,
}: Props): JSX.Element => {
  const [mondayTime, setMondayTime] = useState(
    departureTimes ? departureTimes.monday : "07:00"
  );
  const [tuesdayTime, setTuesdayTime] = useState(
    departureTimes ? departureTimes.tuesday : "07:00"
  );
  const [wednesdayTime, setWednesdayTime] = useState(
    departureTimes ? departureTimes.wednesday : "07:00"
  );
  const [thursdayTime, setThursdayTime] = useState(
    departureTimes ? departureTimes.thursday : "07:00"
  );
  const [fridayTime, setFridayTime] = useState(
    departureTimes ? departureTimes.friday : "07:00"
  );
  const [saturdayTime, setSaturdayTime] = useState(
    departureTimes ? departureTimes.saturday : "07:00"
  );
  const [sundayTime, setSundayTime] = useState(
    departureTimes ? departureTimes.sunday : "07:00"
  );

  useEffect(() => {
    if (
      mondayTime &&
      tuesdayTime &&
      wednesdayTime &&
      thursdayTime &&
      fridayTime &&
      saturdayTime &&
      sundayTime
    ) {
      onDepartureTimesChange({
        monday: mondayTime,
        tuesday: tuesdayTime,
        wednesday: wednesdayTime,
        thursday: thursdayTime,
        friday: fridayTime,
        saturday: saturdayTime,
        sunday: sundayTime,
        departureTimeOverride: null,
      });
    }
  }, [
    mondayTime,
    tuesdayTime,
    wednesdayTime,
    thursdayTime,
    fridayTime,
    saturdayTime,
    sundayTime,
  ]);

  return (
    <Col
      $childSpacing={Spacing.TINY}
      $horizontalAlignChildren={HorizontalAlignment.CENTER}
      style={{ width: "100%" }}
    >
      <DayOfWeek
        disabled={disabled}
        day="Mandag"
        time={mondayTime}
        onTimeChange={(value: string) => setMondayTime(value)}
      />
      <DayOfWeek
        disabled={disabled}
        day="Tirsdag"
        time={tuesdayTime}
        onTimeChange={(value: string) => setTuesdayTime(value)}
      />
      <DayOfWeek
        disabled={disabled}
        day="Onsdag"
        time={wednesdayTime}
        onTimeChange={(value: string) => setWednesdayTime(value)}
      />
      <DayOfWeek
        disabled={disabled}
        day="Torsdag"
        time={thursdayTime}
        onTimeChange={(value: string) => setThursdayTime(value)}
      />
      <DayOfWeek
        disabled={disabled}
        day="Fredag"
        time={fridayTime}
        onTimeChange={(value: string) => setFridayTime(value)}
      />
      <DayOfWeek
        disabled={disabled}
        day="Lørdag"
        time={saturdayTime}
        onTimeChange={(value: string) => setSaturdayTime(value)}
      />
      <DayOfWeek
        disabled={disabled}
        day="Søndag"
        time={sundayTime}
        onTimeChange={(value: string) => setSundayTime(value)}
      />
    </Col>
  );
};

interface DayOfWeekProps {
  day: string;
  time: string;
  onTimeChange: (value: string) => void;
  disabled: boolean;
}

const DayOfWeek = ({
  day,
  disabled,
  time,
  onTimeChange,
}: DayOfWeekProps): JSX.Element => {
  return (
    <Row
      $verticalAlignChildren={VerticalAlignment.CENTER}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        padding: "4px",
        width: "100%",
      }}
    >
      <Typography
        text={day}
        emphasized
        textColor={disabled ? TextColor.LIGHT_PRIMARY : TextColor.PRIMARY}
      />

      <TimeInput
        value={time}
        onValueChanged={onTimeChange}
        backgroundColor={disabled ? TextColor.LIGHT_SUCCESS : TextColor.SUCCESS}
        color={disabled ? TextColor.LIGHT_PRIMARY : TextColor.PRIMARY}
        disabled={disabled}
      />
    </Row>
  );
};

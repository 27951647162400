import { NavigateOptions, useNavigate } from "react-router-dom";

export interface NavigatorFunction {
  back: () => void;
  to: (to: string, options?: NavigateOptions) => void;
}

export const useNavigator = (): NavigatorFunction => {
  const navigate = useNavigate();

  function back() {
    // eslint-disable-next-line no-magic-numbers
    navigate(-1);
  }

  function to(to: string, options?: NavigateOptions) {
    navigate(to, options);
  }

  return { back, to };
};

import { Header, Input, InputType, Spacing } from "../../../components";
import { CancelOrConfirm } from "../../../components/CancelOrConfirm";
import { Typography } from "../../../components/Typography";
import { ScreenWrapper } from "../ScreenWrapper";
import { SmartChargingBaseProps } from "./ConfigureSmartCharging";

interface BatteryLevelProps extends SmartChargingBaseProps {
  directFocus?: boolean;
  desiredFocus?: boolean;
  directBatteryLevel?: string;
  desiredBatteryLevel?: string;
  onDirectBatteryLevelChange: (value: number | undefined) => void;
  onDesiredBatteryLevelChange: (value: number | undefined) => void;
  goToOverview: () => void;
}

export const BatteryLevels = ({
  header,
  subHeader,
  directFocus = false,
  desiredFocus = false,
  directBatteryLevel,
  desiredBatteryLevel,
  onDirectBatteryLevelChange,
  onDesiredBatteryLevelChange,
  goToOverview,
}: BatteryLevelProps): JSX.Element => {
  const handleOnCancelClick = () => {
    onDirectBatteryLevelChange(undefined);
    onDesiredBatteryLevelChange(undefined);
    goToOverview();
  };

  return (
    <ScreenWrapper
      childSpacing={Spacing.SMALL}
      header={
        <Header
          text={header}
          subHeader={subHeader}
          onBackClick={goToOverview}
        />
      }
    >
      <Typography text="Direkte ladenivå (%)" emphasized />

      <Typography text="Dette ladenivået vil alltid bli prioritert idet du tilkobler laderen, før smartladingen vurderer en ladepause for å spare penger." />

      <Input
        type={InputType.NUMBER}
        id="direct-battery-level"
        value={directBatteryLevel ?? ""}
        focus={directFocus}
        onChange={(value) => onDirectBatteryLevelChange(parseInt(value))}
      />

      <Typography text="Ønsket maksimum ladenivå (%)" emphasized />

      <Typography text="Dette ladenivået blir målet for smartladeøktene. Lading vil så stoppes når nivået nås." />

      <Input
        type={InputType.NUMBER}
        id="desired-battery-level"
        value={desiredBatteryLevel ?? ""}
        focus={desiredFocus}
        onChange={(value) => onDesiredBatteryLevelChange(parseInt(value))}
      />

      <CancelOrConfirm
        onCancel={handleOnCancelClick}
        saveTextOverride="Bekreft"
        onSave={goToOverview}
      />
    </ScreenWrapper>
  );
};

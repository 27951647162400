import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const RightArrowIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        d="M8.62501 2.716L17.909 12L8.625 21.284L8.62501 2.716Z"
        fill={fill}
        stroke={fill}
        strokeWidth="2.25"
      />
    </>
  );
};

import { useTheme } from "styled-components";

export const ChargingUntilIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.success;

  return (
    <>
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4C0 5.23544 0.686838 7.61561 3.33333 7.95846V5H2L4.66667 0V3H6L3.35408 7.9611C3.55767 7.98656 3.77278 8 4 8C7.2 8 8 5.33333 8 4V0H4.66667H0Z"
        fill={fill}
      />
    </>
  );
};

import { ChangeEvent } from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";
import { ThemeType } from "../theme/ThemeType";
import { TextColor, propsToTextColor } from "../util";

interface TimeInputProps {
  value: string;
  backgroundColor?: TextColor;
  color?: TextColor;
  disabled?: boolean;
  onValueChanged: (newValue: string) => void;
}
export const TimeInput = ({
  backgroundColor,
  color,
  value,
  disabled,
  onValueChanged,
}: TimeInputProps) => {
  const theme = useTheme();
  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    onValueChanged(value);
  };

  return (
    <StyledTimeInput
      min="00:00"
      max="23:59"
      disabled={disabled}
      $color={color}
      $backgroundColor={backgroundColor}
      theme={theme}
      onChange={onChange}
      value={value}
    />
  );
};

interface StyledTimeInputProps {
  $backgroundColor?: TextColor;
  $color?: TextColor;
  theme?: DefaultTheme;
}

const StyledTimeInput = styled.input.attrs({
  type: "time",
})<StyledTimeInputProps>`
  border: 0;
  font-size: 1rem;
  line-height: 1rem;
  width: 100%;
  color: ${(props) =>
    props.$color
      ? propsToTextColor({
          $textColor: props.$color,
          theme: props.theme,
        })
      : props.theme.colors.text[500]};
  font-weight: 900;

  background: ${(props) =>
    props.$backgroundColor
      ? propsToTextColor({
          $textColor: props.$backgroundColor,
          theme: props.theme,
        })
      : props.theme.colors.background[500]};
  border-radius: 4px;
  padding: 8px 16px;

  // Needed to make the clock icon white for dark themes in chrome based browsers
  ${(props) =>
    props.theme.type === ThemeType.DARK &&
    `
      ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }
    `};
`;

export enum IconType {
  BACK = "BACK",
  CHECK = "CHECK",
  CHECKEDCHECKBOX = "CHECKED_CHECKBOX",
  COLLAPSE = "COLLAPSE",
  CONNECTION = "CONNECTION",
  CONTINUE = "CONTINUE",
  DELETE = "DELETE",
  EDIT = "EDIT",
  EXCLAMATION = "EXCLAMATION",
  EXPAND = "EXPAND",
  FINISH = "FINISH",
  LEFT_ARROW = "LEFT_ARROW",
  OVERVIEW = "OVERVIEW",
  PLUS = "PLUS",
  RIGHT_ARROW = "RIGHT_ARROW",
  SETTINGS = "SETTINGS",
  UNCHECKEDCHECKBOX = "UNCHECKED_CHECKBOX",
  ZAP = "ZAP",
}

import { Header, IconType } from "../../../components";
import { ChargingLocationsDocument } from "../../../generated/graphql/graphql";
import { useNavigator } from "../../../hooks";
import { RouteMap } from "../../Routes";
import { ScreenWrapper } from "../ScreenWrapper";
import { ChargingLocationListEntry } from "./ChargingLocationListEntry";
import { useQuery } from "@apollo/client";
import { toChargingLocation } from "../types/common";

interface ChargingLocationOverviewProps {
  onBackClickOverride?: () => void;
}

export const ChargingLocationsOverview = ({
  onBackClickOverride,
}: ChargingLocationOverviewProps): JSX.Element => {
  const navigate = useNavigator();

  const { data, loading, error } = useQuery(ChargingLocationsDocument, {
    fetchPolicy: "network-only",
  });

  return (
    <ScreenWrapper
      header={
        <Header
          text="Dine ladelokasjoner"
          onBackClick={
            onBackClickOverride ? onBackClickOverride : () => navigate.back()
          }
          buttonRight={{
            icon: IconType.PLUS,
            onClick: () => {
              navigate.to(RouteMap.integrations.chargingLocations.add);
            },
          }}
        />
      }
      queryError={error}
      queryLoading={loading}
    >
      {data?.jedlixChargingLocations.chargingLocations.map(
        (chargingLocation) => (
          <ChargingLocationListEntry
            key={chargingLocation.id}
            chargingLocation={toChargingLocation(chargingLocation)}
            onClick={() =>
              navigate.to(
                RouteMap.integrations.chargingLocations.update.createPathForLocationId(
                  chargingLocation.id
                )
              )
            }
          />
        )
      )}
    </ScreenWrapper>
  );
};

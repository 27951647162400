import { useParams } from "react-router-dom";
import { Header, IconType } from "../../../components";
import { Typography } from "../../../components/Typography";
import { LocationSelection } from "./LocationSelection";
import { CancelOrConfirm } from "../../../components/CancelOrConfirm";
import { useNavigator } from "../../../hooks";
import { RouteMap } from "../../Routes";
import { useState } from "react";
import { ScreenWrapper } from "../ScreenWrapper";
import useChargingLocation from "./ChargingLocationHook";
import { ChargingLocationBase } from "../types/common";

export const UpdateChargingLocation = (): JSX.Element => {
  const { chargingLocationId } = useParams<{ chargingLocationId: string }>();
  const {
    chargingLocation,
    queryError,
    queryLoading,
    mutationLoading,
    mutationError,
    updateChargingLocation,
  } = useChargingLocation(chargingLocationId);
  const [locationInput, setLocationInput] =
    useState<ChargingLocationBase | null>();
  const navigate = useNavigator();

  return (
    <ScreenWrapper
      header={
        <Header
          text="Endre lokasjon"
          buttonRight={{
            icon: IconType.DELETE,
            onClick: () => {
              if (chargingLocationId) {
                navigate.to(
                  RouteMap.integrations.chargingLocations.delete.createPathForLocationId(
                    chargingLocationId
                  )
                );
              }
            },
          }}
        />
      }
      queryLoading={queryLoading}
      queryError={queryError}
    >
      <Typography text="Du endrer lokasjonen om du legger inn en ny adresse i feltet nedenfor. Vil du slette lokasjonen, klikk på ikonet oppe til høyre." />

      <LocationSelection
        onLocationChanged={setLocationInput}
        initialLocation={chargingLocation}
      />

      <CancelOrConfirm
        mutationLoading={mutationLoading}
        mutationError={mutationError}
        onCancel={() => navigate.back()}
        onSave={() => {
          if (chargingLocationId && locationInput) {
            const { address, latLng } = locationInput;
            updateChargingLocation({
              chargingLocationId,
              address: {
                country: "NO",
                postalCode: address.postalCode,
              },
              coordinates: {
                latitude: latLng.latitude,
                longitude: latLng.longitude,
              },
            });
          }
        }}
        saveDisabled={!chargingLocationId}
      />
    </ScreenWrapper>
  );
};

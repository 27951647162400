import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const CheckIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.success[500];

  return (
    <>
      <line
        x1="0.707107"
        y1="5.91789"
        x2="4.70711"
        y2="9.91789"
        stroke={fill}
        strokeWidth="2"
      />
      <line
        x1="3.29289"
        y1="9.91789"
        x2="11.2929"
        y2="1.91789"
        stroke={fill}
        strokeWidth="2"
      />
    </>
  );
};

import { TextColor } from "../util";
import { Icon, IconType } from "./icon";

interface Props {
  checked: boolean;
  checkedColor?: TextColor;
  uncheckedColor?: TextColor;
  onClick: () => void;
}

export const Checkbox = ({
  checked,
  checkedColor,
  uncheckedColor,
  onClick,
}: Props): JSX.Element => {
  return checked ? (
    <Icon
      type={IconType.CHECKEDCHECKBOX}
      color={checkedColor}
      onClick={onClick}
      size={24}
    />
  ) : (
    <Icon
      type={IconType.UNCHECKEDCHECKBOX}
      color={uncheckedColor}
      onClick={onClick}
      size={24}
    />
  );
};

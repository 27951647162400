import { DefaultTheme } from "styled-components";
import { ThemeType } from "./ThemeType";

export const LightTheme: DefaultTheme = {
  colors: {
    background: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(0, 0, 0, 0.05)",
    },

    danger: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "300": "rgba(253,155,152, 0.25)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(253,155,152, 1)",
    },

    modalBackground: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "#ffffff",
    },

    success: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "300": "rgba(0, 190, 156, 0.5)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(0, 190, 156, 1)",
    },

    text: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "100": "rgba(68, 68, 68, 0.0375)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "200": "rgba(68, 68, 68, 0.05)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "300": "rgba(68, 68, 68, 0.5)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(68, 68, 68, 1)",
    },

    warning: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(254, 214, 74, 1)",
    },

    disable: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(94, 160, 148, 1)",
    },
  },
  type: ThemeType.LIGHT,
};

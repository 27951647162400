import styled, { DefaultTheme, useTheme } from "styled-components";
import { Icon, IconProps } from "./icon";
import { TextColor, propsToTextColor } from "../util";

interface RoundButtonProps {
  onClick: () => void;
  icon?: IconProps;
  color?: TextColor;
  text?: string;
  backgroundColor?: TextColor;
  big?: boolean;
  disabled?: boolean;
}

export const RoundButton = ({
  onClick,
  icon,
  text,
  backgroundColor,
  big = false,
  disabled,
}: RoundButtonProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      $big={big}
      $backgroundColor={backgroundColor}
      $theme={theme}
      disabled={disabled}
    >
      {text}
      {icon && (
        <Icon
          type={icon.type}
          color={icon.color}
          size={icon.size}
          centerVertically
        />
      )}
    </Button>
  );
};

interface ButtonProps {
  $big?: boolean;
  $backgroundColor?: TextColor;
  $theme: DefaultTheme;
}

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.$big ? "48px" : "32px")};
  height: ${(props) => (props.$big ? "48px" : "32px")};
  background: ${(props) =>
    props.$backgroundColor
      ? propsToTextColor({
          $textColor: props.$backgroundColor,
          theme: props.$theme,
        })
      : props.$theme.colors.background[500]};
  border-radius: 32px;
  border: 0;
`;

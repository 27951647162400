import { Vehicle } from "../app/jedlix/types/common";

export const vehicleToVehicleName = (vehicle: Vehicle): string => {
  if (!vehicle.details) {
    return "";
  }

  const model = stripParentheses(vehicle.details.model);

  return `${vehicle.details.brand} ${model}`;
};

function stripParentheses(text: string): string {
  return text.replace(/\(.*?\)/g, "").trim();
}

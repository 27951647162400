import { useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  Card,
  ErrorText,
  Icon,
  IconType,
  Loader,
  Row,
  Spacing,
  TextAlign,
  Toggle,
} from "../../../components";
import { Typography, TypographyVariant } from "../../../components/Typography";
import { DepartureTimes } from "../../../generated/graphql/graphql";
import { useChargeSettings } from "./ChargeSettingsHook";
import { DateTime } from "luxon";

interface Props {
  numberOfChargingLocations: number;
  goToConfigureSmartCharging: () => void;
  vehicleId: string;
}

export const SmartChargingStatusCard = ({
  numberOfChargingLocations,
  goToConfigureSmartCharging,
  vehicleId,
}: Props): JSX.Element => {
  const {
    chargeSettings,
    loadingQuery,
    errorQuery,
    loadingMutation,
    update,
    refetch,
  } = useChargeSettings(vehicleId);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    refetch();
  });

  useEffect(() => {
    if (!chargeSettings) {
      return;
    }
    setIsToggled(chargeSettings.isSmartChargingEnabled);
  }, [chargeSettings]);

  const handleToggle = () => {
    if (!chargeSettings) {
      return;
    }
    update({
      ...chargeSettings,
      maxAllowedTargetSoC: 0,
      isSolarChargingEnabled: false,
      timeZone: "EUROPE_OSLO",
      isSmartChargingEnabled: !isToggled,
    });
  };

  return (
    <Card
      $padding={Spacing.SMALL}
      style={{
        width: "100%",
      }}
    >
      {loadingQuery ? (
        <Loader size="FULLSCREEN" />
      ) : errorQuery || !chargeSettings ? (
        <ErrorText text="error" />
      ) : (
        <>
          <Row
            style={{
              display: "grid",
              gap: "1rem",
              gridTemplateColumns: "1fr 16px 40px",
              alignItems: "center",
              verticalAlign: "baseline",
            }}
          >
            <Typography
              text="Smartlading"
              emphasized={true}
              variant={TypographyVariant.HEADER}
            />

            <SettingsWrapper $active={isToggled}>
              <Icon
                style={{ position: "relative", top: "4px", left: "4px" }}
                type={IconType.SETTINGS}
                onClick={goToConfigureSmartCharging}
                centerVertically={true}
                size={12}
              />
            </SettingsWrapper>
            <Toggle
              id="enableSmartCharging"
              onToggled={handleToggle}
              checked={isToggled}
              disabled={loadingMutation}
            />
          </Row>

          <Row
            style={{
              padding: `${Spacing.SMALL} 0`,
            }}
          >
            {/* <Typography
          text="Lading er nå pauset frem til et mer rimelig tidsrom. Batteriet kommer til å nå ønsket nivå innen neste frist." // TODO: make dynamic
          emphasized={false}
          variant={TypographyVariant.PARAGRAPH}
        /> */}
          </Row>

          <DetailWrapper>
            <Typography
              text={"Lader umiddelbart til"}
              emphasized={true}
              variant={TypographyVariant.PARAGRAPH}
            />

            <Icon type={IconType.ZAP} />
            <Typography
              textAlign={TextAlign.RIGHT}
              text={`${chargeSettings.directChargingBatteryLevel}%`}
              emphasized={true}
              variant={TypographyVariant.PARAGRAPH}
            />
          </DetailWrapper>

          <DetailWrapper>
            <Typography
              text={"Smartlader opp til"}
              emphasized={true}
              variant={TypographyVariant.PARAGRAPH}
            />

            <Icon type={IconType.FINISH} />
            <Typography
              textAlign={TextAlign.RIGHT}
              text={`${chargeSettings.desiredBatteryLevel}%`}
              emphasized={true}
              variant={TypographyVariant.PARAGRAPH}
            />
          </DetailWrapper>

          <DetailWrapper>
            <Typography
              text={"Neste frist"}
              emphasized={true}
              variant={TypographyVariant.PARAGRAPH}
            />
            <div></div>
            <Typography
              textAlign={TextAlign.RIGHT}
              text={`${getNextDepartureTime(chargeSettings.departureTimes)}`}
              emphasized={true}
              variant={TypographyVariant.PARAGRAPH}
            />
          </DetailWrapper>

          <DetailWrapper>
            <Typography
              text={"Lokasjoner lagt til"}
              emphasized={true}
              variant={TypographyVariant.PARAGRAPH}
            />
            <div></div>
            <Typography
              textAlign={TextAlign.RIGHT}
              text={`${numberOfChargingLocations}`}
              emphasized={true}
              variant={TypographyVariant.PARAGRAPH}
            />
          </DetailWrapper>
        </>
      )}
    </Card>
  );
};

function getNextDepartureTime(departureTimes: DepartureTimes): string {
  const getDepartureForWeekday = (weekday: number) => {
    switch (weekday) {
      case 1:
        return departureTimes.monday;
      case 2:
        return departureTimes.tuesday;
      case 3:
        return departureTimes.wednesday;
      case 4:
        return departureTimes.thursday;
      case 5:
        return departureTimes.friday;
      case 6:
        return departureTimes.saturday;
      case 7:
        return departureTimes.sunday;
      default:
        throw new Error("Invalid weekday");
    }
  };

  const now = DateTime.now();
  const weekday = now.get("weekday");
  const currentDayDepartureTime = getDepartureForWeekday(weekday);

  // All times are in local time zone
  if (DateTime.fromFormat(currentDayDepartureTime, "hh:mm") < now) {
    const weekdayTomorrow = DateTime.now().plus({ days: 1 }).get("weekday");
    return getDepartureForWeekday(weekdayTomorrow);
  }
  return currentDayDepartureTime;
}

const DetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 50px;
  margin-bottom: 0.8rem;
`;

const SettingsWrapper = styled.div<{ $active?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: ${(props) =>
    props.$active ? props.theme.colors.success[300] : "white"};
  cursor: pointer;
  transition: background-color 0.2s;
`;

import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const PlusIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.875 0H7.125V4.875H12V7.125H7.125V12H4.875V7.125H0V4.875H4.875V0Z"
        fill={fill}
      />
    </>
  );
};

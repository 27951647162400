/* eslint-disable no-magic-numbers */
import styled, { CSSProperties } from "styled-components";
import { propsToTextColor, TextColor, VariantProps } from "../util";
import { Icon, IconProps } from "./icon";
import { Loader } from "./Loader";

export enum ButtonVariant {
  "DANGER",
  "SUCCESS",
}

interface Props {
  loading?: boolean;
  text: string;
  textColor?: TextColor;
  variant?: ButtonVariant;
  style?: CSSProperties;
  disabled?: boolean;
  fullWidth?: boolean;
  iconLeft?: IconProps;
  iconRight?: IconProps;
  onClick: () => void;
}

export const Button = ({
  loading,
  text,
  variant,
  textColor = TextColor.PRIMARY,
  disabled: formInvalid = false,
  fullWidth = false,
  iconLeft,
  iconRight,
  style,
  onClick,
}: Props): JSX.Element => {
  const disabled = formInvalid || loading;

  return (
    <StyledButton
      $textColor={textColor}
      style={style}
      disabled={disabled}
      $variant={variant}
      $fullWidth={fullWidth}
      $hasIcon={!!iconLeft || !!iconRight}
      onClick={onClick}
    >
      {iconLeft && (
        <Icon
          type={iconLeft.type}
          size={iconLeft.size}
          color={iconLeft.color}
        />
      )}
      {loading ? <Loader size="SMALL" /> : text}
      {iconRight && (
        <Icon
          type={iconRight.type}
          size={iconRight.size}
          color={iconRight.color}
        />
      )}
    </StyledButton>
  );
};

interface StyledButtonProps extends VariantProps {
  $hasIcon: boolean;
  $variant?: ButtonVariant;
  $fullWidth: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  border: ${(props) => `1px solid ${props.theme.colors.text[200]}`};

  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.15),
    1px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px;

  font-family: "Roboto";
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: ${(props) => propsToTextColor(props)};

  ${(props) =>
    props.$hasIcon &&
    `
      justify-content: space-between;
      display: flex;
    `}

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
    `}

  ${(props) =>
    props.$fullWidth &&
    `
      width: 100%;
    `}

    ${(props) =>
    props.$variant === ButtonVariant.DANGER &&
    `
        background: ${props.theme.colors.danger["500"]}
      `}

      ${(props) =>
    props.$variant === ButtonVariant.SUCCESS &&
    `
            background: ${props.theme.colors.success["500"]}
          `}
`;

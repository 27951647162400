import { ApolloError } from "@apollo/client";
import { Button, ButtonVariant, ErrorText, Row } from ".";

interface Props {
  cancelDisabled?: boolean;
  saveDisabled?: boolean;
  mutationLoading?: boolean;
  mutationError?: ApolloError;
  cancelButtonVariant?: ButtonVariant;
  saveButtonVariant?: ButtonVariant;
  cancelTextOverride?: string;
  saveTextOverride?: string;
  onCancel: () => void;
  onSave: () => void;
}

export const CancelOrConfirm = ({
  cancelDisabled = false,
  saveDisabled = false,
  mutationLoading = false,
  mutationError,
  cancelButtonVariant,
  saveButtonVariant = ButtonVariant.SUCCESS,
  cancelTextOverride,
  saveTextOverride,
  onCancel,
  onSave,
}: Props): JSX.Element => {
  return (
    <>
      {mutationError && <ErrorText text={mutationError?.toString()} />}
      <Row
        style={{
          gap: "1rem",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          text={cancelTextOverride || "Avbryt"}
          variant={cancelButtonVariant}
          disabled={cancelDisabled}
          fullWidth
          onClick={onCancel}
        />

        <Button
          text={saveTextOverride || "Lagre"}
          variant={saveButtonVariant}
          disabled={saveDisabled}
          fullWidth
          loading={mutationLoading}
          onClick={onSave}
        />
      </Row>
    </>
  );
};

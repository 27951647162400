import { CSSProperties } from "react";
import { TextColor } from "../../util";
import {
  BackIcon,
  CheckIcon,
  CheckedCheckboxIcon,
  CollapseIcon,
  ContinueIcon,
  DeleteIcon,
  EditIcon,
  ExclamationIcon,
  ExpandIcon,
  LeftArrowIcon,
  OverviewIcon,
  PlusIcon,
  RightArrowIcon,
  SettingsIcon,
  UncheckedCheckboxIcon,
  ZapIcon,
} from "./icons";
import { ConnectionIcon } from "./icons/ConnectionIcon";
import { FinishIcon } from "./icons/FinishIcon";
import { IconType } from "./IconType";

export interface SvgIconProps {
  color?: TextColor;
}

export interface IconProps extends SvgIconProps {
  type: IconType;
  size?: number;
}

interface Props extends IconProps {
  centerVertically?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

export const Icon = ({
  style: customStyle,
  type,
  centerVertically,
  // eslint-disable-next-line no-magic-numbers
  size = 12,
  color,
  onClick,
}: Props): JSX.Element => {
  const style = centerVertically
    ? {
        alignSelf: "center",
        display: "flex",
        ...customStyle,
      }
    : customStyle;
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      {iconTypeToIcon(type, color)}
    </svg>
  );
};

const iconTypeToIcon = (type: IconType, color?: TextColor) => {
  switch (type) {
    case IconType.BACK:
      return <BackIcon color={color} />;

    case IconType.CHECK:
      return <CheckIcon color={color} />;

    case IconType.CHECKEDCHECKBOX:
      return <CheckedCheckboxIcon color={color} />;

    case IconType.COLLAPSE:
      return <CollapseIcon color={color} />;

    case IconType.CONNECTION:
      return <ConnectionIcon color={color} />;

    case IconType.CONTINUE:
      return <ContinueIcon color={color} />;

    case IconType.DELETE:
      return <DeleteIcon color={color} />;

    case IconType.EDIT:
      return <EditIcon color={color} />;

    case IconType.EXCLAMATION:
      return <ExclamationIcon color={color} />;

    case IconType.EXPAND:
      return <ExpandIcon color={color} />;

    case IconType.FINISH:
      return <FinishIcon />;

    case IconType.LEFT_ARROW:
      return <LeftArrowIcon color={color} />;

    case IconType.OVERVIEW:
      return <OverviewIcon color={color} />;

    case IconType.PLUS:
      return <PlusIcon color={color} />;

    case IconType.RIGHT_ARROW:
      return <RightArrowIcon color={color} />;

    case IconType.SETTINGS:
      return <SettingsIcon color={color} />;

    case IconType.UNCHECKEDCHECKBOX:
      return <UncheckedCheckboxIcon color={color} />;

    case IconType.ZAP:
      return <ZapIcon />;
  }
};

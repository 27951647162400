import { Routes as ReactRoutes, Route } from "react-router-dom";
import {
  ConfigureSmartCharging,
  DisconnectVehicle,
  Overview,
  VehicleOverview,
} from "./jedlix";
import {
  ChargingLocationsOverview,
  CreateChargingLocation,
  UpdateChargingLocation,
  DeleteChargingLocation,
} from "./jedlix/charging-locations";

export const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      <Route path={RouteMap.base} element={<Overview />} />

      <Route
        path={RouteMap.integrations.disconnect}
        element={<DisconnectVehicle />}
      />

      <Route
        path={RouteMap.integrations.vehicle.basePath}
        element={<VehicleOverview />}
      />

      <Route
        path={RouteMap.integrations.vehicle.smartCharging.basePath}
        element={<ConfigureSmartCharging />}
      />

      <Route
        path={RouteMap.integrations.chargingLocations.add}
        element={<CreateChargingLocation />}
      />

      <Route
        path={RouteMap.integrations.chargingLocations.basePath}
        element={<ChargingLocationsOverview />}
      />

      <Route
        path={RouteMap.integrations.chargingLocations.update.basePath}
        element={<UpdateChargingLocation />}
      />

      <Route
        path={RouteMap.integrations.chargingLocations.delete.basePath}
        element={<DeleteChargingLocation />}
      />
    </ReactRoutes>
  );
};

export const RouteMap = {
  base: "/",
  integrations: {
    chargingLocations: {
      add: "/charging-locations/add",
      basePath: "/charging-locations",
      delete: {
        basePath: "/charging-locations/:chargingLocationId/delete",
        createPathForLocationId: (chargingLocationId: string): string =>
          `/charging-locations/${chargingLocationId}/delete`,
      },
      update: {
        basePath: "/charging-locations/:chargingLocationId/update",
        createPathForLocationId: (chargingLocationId: string): string =>
          `/charging-locations/${chargingLocationId}/update`,
      },
    },
    disconnect: "/integrations/disconnect",
    vehicle: {
      basePath: "/vehicles/:vehicleId",
      createPathForVehicleId: (vehicleId: string): string =>
        `/vehicles/${vehicleId}`,
      smartCharging: {
        basePath: "/vehicles/:vehicleId/configure-smart-charging",
        createPathForVehicleId: (vehicleId: string): string =>
          `/vehicles/${vehicleId}/configure-smart-charging`,
      },
    },
  },
};

import { Card } from "../../components/Card";
import { Col } from "../../components/Col";
import { Spacing } from "../../components/constraints/Spacing";
import { Typography, TypographyVariant } from "../../components/Typography";
import { vehicleToVehicleName } from "../../util";
import { BatteryProgressBar } from "./BatteryProgressBar";
import { ChargingStatus } from "./ChargingStatus";
import { Vehicle } from "./types/common";

interface Props {
  vehicle: Vehicle;
  onClick: () => void;
}

export const VehiclesOverviewListEntry = ({
  vehicle,
  onClick,
}: Props): JSX.Element => {
  return (
    <Card
      $padding={Spacing.SMALL}
      key={vehicle.id}
      onClick={onClick}
      style={{ width: "100%" }}
    >
      <Col
        style={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
        }}
        $childSpacing={Spacing.TINY}
      >
        <Typography
          text={vehicleToVehicleName(vehicle)}
          variant={TypographyVariant.LABEL}
          emphasized
        />

        <BatteryProgressBar
          width="calc(100% - 64px)"
          batteryChargeState={vehicle.chargeState?.chargingStatus ?? "UNKNOWN"}
          progress={vehicle.chargeState?.batteryLevel ?? 0}
          range={vehicle.chargeState?.range ?? 0}
        />

        <ChargingStatus vehicle={vehicle} />
      </Col>
    </Card>
  );
};

import styled, { CSSProperties } from "styled-components";

interface Props {
  text?: string;
  style?: CSSProperties;
}

export const Label = ({ text }: Props) => {
  return <StyledLabel>{text}</StyledLabel>;
};

const StyledLabel = styled.label`
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: ${(props) => props.theme.colors.text[500]};
  font-variant: all-small-caps;
  text-transform: lowercase;

  margin-left: 4px;
`;

import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const EditIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];

  return (
    <>
      <path d="M0 9.75V12H2.25L0 9.75Z" fill={fill} />
      <path
        d="M3 11.25L0.75 9L8.25 1.5L9 0.75L10.5 2.25L9.75 3L10.5 3.75L3 11.25Z"
        fill={fill}
      />
      <path d="M11.25 3L9 0.75L9.75 0L12 2.25L11.25 3Z" fill={fill} />
    </>
  );
};

import { useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import {
  Col,
  ErrorText,
  Label,
  Loader,
  Row,
  Spacing,
} from "../../../components";
import { Typography } from "../../../components/Typography";
import { ChargingLocationBase } from "../types/common";

interface Props {
  onLocationChanged: (chargingLocationBase: ChargingLocationBase) => void;
  initialLocation: ChargingLocationBase | null;
}

export const LocationSelection = ({
  onLocationChanged: onLocationChanged,
  initialLocation,
}: Props): JSX.Element => {
  const [place, setPlace] = useState<PlaceResult | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isFetchingLatLng, setIsFetchingLatLng] = useState(!!initialLocation);
  const hasBeenInitiatedRef = useRef(false);

  const updatePlace = (place: PlaceResult | null): void => {
    setPlace(place);
  };

  useEffect(() => {
    const initPlaceForLocation = async () => {
      if (initialLocation) {
        const { latLng } = initialLocation;

        const result = await geocodeByLatLng({
          lat: latLng.latitude,
          lng: latLng.longitude,
        });
        const [firstResult] = result;

        setPlace({
          label: firstResult.formatted_address,
          value: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            place_id: firstResult.place_id,
            types: firstResult.types,
          },
        });
        result[0].formatted_address;
      } else {
        setPlace(null);
      }
    };
    if (hasBeenInitiatedRef.current === false && initialLocation) {
      initPlaceForLocation();
      hasBeenInitiatedRef.current = true;
    }
  }, [initialLocation]);

  useEffect(() => {
    const updateLatLngForPlace = async () => {
      if (!place) {
        return;
      }
      setErrorMessage(undefined);

      setIsFetchingLatLng(true);

      try {
        const result = await geocodeByPlaceId(place.value.place_id);

        const latitude = result[0].geometry.location.lat();
        const longitude = result[0].geometry.location.lng();
        const postalCode = result[0].address_components.find((component) =>
          component.types.includes("postal_code")
        )?.long_name;

        if (!postalCode) {
          throw new Error("No postal code found");
        }

        onLocationChanged({
          address: {
            country: "NO",
            postalCode,
          },
          latLng: {
            latitude,
            longitude,
          },
        });
        setIsFetchingLatLng(false);
      } catch (err) {
        setIsFetchingLatLng(false);
        setErrorMessage(
          "Kunne ikke finne lokasjon for adresse, prøv igjen eller oppgi en annen adresse"
        );
      }
    };

    updateLatLngForPlace();
  }, [place]);

  return (
    <Col style={{ height: "62px", width: "100%" }}>
      <Label text="Gateadresse" />
      {isFetchingLatLng ? (
        <Row $childSpacing={Spacing.SMALL} style={{ justifyContent: "center" }}>
          <Loader />
          <Typography text="Henter posisjon" />
        </Row>
      ) : (
        <GooglePlacesAutocomplete
          minLengthAutocomplete={5}
          selectProps={{
            onChange: updatePlace,
            placeholder: "Adresse med gatenummer…",
            value: place,
          }}
          autocompletionRequest={{ types: ["address"] }}
        />
      )}
      {errorMessage && <ErrorText text={errorMessage} />}
    </Col>
  );
};

interface PlaceResult {
  label: string;
  value: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    place_id: string;
    types: string[];
  };
}

import { Col, Header, Row, Spacing } from "../../../components";
import { useQuery } from "@apollo/client";
import { ChargingLocationsDocument } from "../../../generated/graphql/graphql";
import { BatteryProgressBar } from "../BatteryProgressBar";
import { ChargingStatus } from "../ChargingStatus";
import { RouteMap } from "../../Routes";
import { vehicleToVehicleName } from "../../../util";
import { useNavigator } from "../../../hooks";
import { ScreenWrapper } from "../ScreenWrapper";
import { ErrorScreen } from "../ErrorScreen";
import { SmartChargingStatusCard } from "../smart-charging/SmartChargingStatusCard";
import { useVehicle } from "./VehicleProvider";

// Overview vehicle with title, battery status, charging status, and buttons for smart charging and charging history

export const VehicleOverview = (): JSX.Element => {
  const { vehicle } = useVehicle();
  const navigate = useNavigator();

  const {
    data: dataChargingLocations,
    loading: loadingChargingLocations,
    error: errorChargingLocations,
  } = useQuery(ChargingLocationsDocument);

  if (!vehicle) {
    return <ErrorScreen headerText="Bil" errorText="Kunne ikke finne bilen" />;
  }

  const goToConfigureSmartCharging = () => {
    navigate.to(
      RouteMap.integrations.vehicle.smartCharging.createPathForVehicleId(
        vehicle.id
      )
    );
  };

  return (
    <ScreenWrapper
      childSpacing={Spacing.MEDIUM}
      header={<Header text={vehicleToVehicleName(vehicle)} />}
      queryLoading={loadingChargingLocations}
      queryError={errorChargingLocations}
    >
      <Row
        style={{
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Col
          $childSpacing={Spacing.TINY}
          style={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          {vehicle && vehicle.chargeState && vehicle.details && (
            <BatteryProgressBar
              width="calc(100% - 64px)"
              height="40px"
              batteryChargeState={
                vehicle.chargeState?.chargingStatus ?? "UNKNOWN"
              }
              progress={vehicle.chargeState?.batteryLevel ?? 0}
              range={vehicle.chargeState?.range ?? 0}
            />
          )}

          {vehicle && vehicle.details && <ChargingStatus vehicle={vehicle} />}
        </Col>
      </Row>

      <SmartChargingStatusCard
        vehicleId={vehicle.id}
        goToConfigureSmartCharging={goToConfigureSmartCharging}
        numberOfChargingLocations={
          dataChargingLocations?.jedlixChargingLocations.chargingLocations
            .length ?? 0
        }
      />
      {/* <Button
        text="Ladehistorikk"
        iconRight={{
          type: IconType.CONTINUE,
          size: 16,
        }}
        fullWidth
        onClick={() => {
          // TODO: Implement charging history button click
          console.log("Not implemented yet");
        }}
      /> */}
    </ScreenWrapper>
  );
};

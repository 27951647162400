import { DefaultTheme } from "styled-components";

export enum TextColor {
  PRIMARY = "PRIMARY",
  LIGHT_PRIMARY = "LIGHT_PRIMARY",
  SUCCESS = "SUCCESS",
  LIGHT_SUCCESS = "LIGHT_SUCCESS",
  DANGER = "DANGER",
  LIGHT_DANGER = "LIGHT_DANGER",
  WARNING = "WARNING",
  DISABLED = "DISABLED",
}

export interface VariantProps {
  $textColor: TextColor;
  theme: DefaultTheme;
}

export const propsToTextColor = (props: VariantProps) => {
  switch (props.$textColor) {
    case TextColor.PRIMARY:
      return props.theme.colors.text[500];
    case TextColor.LIGHT_PRIMARY:
      return props.theme.colors.text[300];
    case TextColor.DANGER:
      return props.theme.colors.danger[500];
    case TextColor.LIGHT_DANGER:
      return props.theme.colors.danger[300];
    case TextColor.SUCCESS:
      return props.theme.colors.success[500];
    case TextColor.LIGHT_SUCCESS:
      return props.theme.colors.success[300];
    case TextColor.WARNING:
      return props.theme.colors.warning[500];
    case TextColor.DISABLED:
      return props.theme.colors.disable[500];
  }
};

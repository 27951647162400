import { useTheme } from "styled-components";

export const NotPluggedInIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.text;

  return (
    <>
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00012 2.99995C1.00011 2.99994 1.0001 2.99994 1.00009 2.99995C1.00008 2.99996 1.00008 2.99996 1.00008 2.99997V5.95854C1.00008 5.98506 0.989547 6.0105 0.970793 6.02925L0.0707932 6.92925C0.0317408 6.9683 0.0317408 7.03162 0.0707933 7.07067L0.929372 7.92925C0.968424 7.9683 1.03174 7.9683 1.07079 7.92925L1.97079 7.02925C1.98955 7.0105 2.01498 6.99996 2.0415 6.99996H5.00007C5.00008 6.99996 5.00008 6.99996 5.00009 6.99995C5.0001 6.99994 5.0001 6.99993 5.00009 6.99992L1.00012 2.99995ZM7.07082 4.92923C7.03176 4.96828 6.96845 4.96828 6.92939 4.92923L3.07082 1.07065C3.03176 1.0316 3.03176 0.96828 3.07082 0.929227L3.42937 0.570671C3.46842 0.531619 3.53174 0.531619 3.57079 0.570671L3.92937 0.92925C3.96842 0.968302 4.03174 0.968302 4.07079 0.92925L4.92937 0.0706712C4.96842 0.0316188 5.03174 0.0316187 5.07079 0.0706711L5.92937 0.92925C5.96842 0.968302 5.96842 1.03162 5.92937 1.07067L5.07079 1.92925C5.03174 1.9683 5.03174 2.03162 5.07079 2.07067L5.92937 2.92925C5.96842 2.9683 6.03174 2.9683 6.07079 2.92925L6.92937 2.07067C6.96842 2.03162 7.03174 2.03162 7.07079 2.07067L7.92937 2.92925C7.96842 2.9683 7.96842 3.03162 7.92937 3.07067L7.07079 3.92925C7.03174 3.9683 7.03174 4.03162 7.07079 4.07067L7.42937 4.42925C7.46842 4.4683 7.46842 4.53162 7.42937 4.57067L7.07082 4.92923Z"
        fill={fill}
      />
      <path
        d="M0.207107 1.20711L6.79289 7.79289C6.9255 7.9255 7.10536 8 7.29289 8C7.68342 8 8 7.68342 8 7.29289C8 7.10536 7.9255 6.9255 7.79289 6.79289L1.20711 0.207107C1.0745 0.0744985 0.894643 0 0.707107 0C0.316583 0 0 0.316583 0 0.707107C0 0.894643 0.0744985 1.0745 0.207107 1.20711Z"
        fill={fill}
      />
    </>
  );
};

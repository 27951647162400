import { ApolloError, useQuery } from "@apollo/client";
import { PropsWithChildren, createContext, useContext } from "react";
import { Loader } from "../../../components";
import {
  JedlixVehicle,
  VehiclesDocument,
} from "../../../generated/graphql/graphql";
import { toVehicle, Vehicle } from "../types/common";

interface VehicleProviderContext {
  vehicle: Vehicle | null;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<void>;
}
const VehicleProviderContext = createContext<
  VehicleProviderContext | undefined
>(undefined);

export const VehicleProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const {
    data: vehiclesData,
    loading,
    error,
    refetch,
  } = useQuery(VehiclesDocument);

  if (loading) {
    return <Loader size="FULLSCREEN" />;
  }

  const vehicle = vehiclesData?.listJedlixVehicles.vehicles.length
    ? toVehicle(vehiclesData.listJedlixVehicles.vehicles[0] as JedlixVehicle)
    : null;

  return (
    <VehicleProviderContext.Provider
      value={{
        vehicle,
        loading,
        error,
        refetch: async () => {
          await refetch();
        },
      }}
    >
      {children}
    </VehicleProviderContext.Provider>
  );
};
export const useVehicle = (): VehicleProviderContext => {
  const context = useContext(VehicleProviderContext);
  if (!context) {
    throw new Error(`useVehicle must be used within a VehicleProvider`);
  }
  return context;
};

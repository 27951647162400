import { TextColor } from "../../util";
import {
  IconType,
  RoundButton,
  Row,
  Spacing,
  VerticalAlignment,
} from "../../components";
import { VehiclesOverviewListEntry } from "./VehiclesOverviewListEntry";
import { RouteMap } from "../Routes";
import { Typography, TypographyVariant } from "../../components/Typography";
import { useNavigator } from "../../hooks";
import { ScreenWrapper } from "./ScreenWrapper";
import { Vehicle } from "./types/common";
import { useVehicle } from "./vehicle/VehicleProvider";

export const Overview = (): JSX.Element => {
  const navigate = useNavigator();

  const { vehicle, loading, error } = useVehicle();

  const goToVehicle = (vehicle: Vehicle) => {
    navigate.to(
      RouteMap.integrations.vehicle.createPathForVehicleId(vehicle.id)
    );
  };

  const goToDisconnectVendor = () => {
    navigate.to(RouteMap.integrations.disconnect);
  };

  const onAddClicked = () => {
    // TODO
  };

  return (
    <ScreenWrapper
      queryLoading={loading}
      queryError={error}
      header={
        <Row
          style={{
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: "1fr 32px 32px",
            width: "100%",
          }}
          $verticalAlignChildren={VerticalAlignment.CENTER}
        >
          <Typography
            variant={TypographyVariant.HEADER}
            text="Dine smarte koblinger"
            emphasized
          />
          <RoundButton
            onClick={goToDisconnectVendor}
            icon={{ type: IconType.DELETE }}
            color={TextColor.DANGER}
          />

          <RoundButton onClick={onAddClicked} icon={{ type: IconType.PLUS }} />
        </Row>
      }
      childSpacing={Spacing.MEDIUM}
    >
      {vehicle && (
        <VehiclesOverviewListEntry
          onClick={() => goToVehicle(vehicle)}
          key={vehicle.id}
          vehicle={vehicle}
        />
      )}
    </ScreenWrapper>
  );
};

import { Card, Checkbox, Row, Spacing } from ".";
import { TextColor } from "../util";
import { Typography, TypographyVariant } from "./Typography";

interface Props {
  label: string;
  checked: boolean;
  checkedColor: "DANGER" | "SUCCESS";
  onClick: () => void;
  contentRight?: JSX.Element;
}

export const CheckboxListEntry = ({
  label,
  checked,
  checkedColor,
  onClick,
  contentRight,
}: Props): JSX.Element => {
  return (
    <Card
      $padding={Spacing.SMALL}
      onClick={onClick}
      $backgroundColor={checked ? checkedColor : "NORMAL"}
      style={{
        width: "100%",
      }}
    >
      <Row
        style={{
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "32px 1fr auto",
          width: "100%",
        }}
        $childSpacing={Spacing.TINY}
      >
        <Checkbox
          checked={checked}
          checkedColor={
            checkedColor === "DANGER" ? TextColor.DANGER : TextColor.SUCCESS
          }
          onClick={onClick}
        />
        <Typography text={label} variant={TypographyVariant.LABEL} emphasized />
        {contentRight}
      </Row>
    </Card>
  );
};

import React, { ReactNode } from "react";
import {
  Col,
  Loader,
  ErrorText,
  Spacing,
  HorizontalAlignment,
} from "../../components";
import { ApolloError } from "@apollo/client";

interface ScreenWrapperProps {
  header: JSX.Element;
  queryLoading?: boolean;
  queryError?: ApolloError;
  childSpacing?: Spacing;
  children?: ReactNode;
}

export const ScreenWrapper = ({
  header,
  queryLoading = false,
  queryError,
  childSpacing = Spacing.TINY,
  children,
}: ScreenWrapperProps): JSX.Element => {
  const renderContent = () => {
    if (queryLoading) return <Loader size="MEDIUM" />;
    if (queryError) return <ErrorText text={queryError.toString()} />;
    return children;
  };

  return (
    <Col
      $childSpacing={childSpacing}
      $horizontalAlignChildren={HorizontalAlignment.CENTER}
      style={{ width: "100%" }}
    >
      {header}
      {renderContent()}
    </Col>
  );
};

import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const ExpandIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.04612 4.50454L3.98148 5.4399L7.96875 1.45264L7.03338 0.51727L3.98443 3.56622L0.967063 0.548848L0.0316976 1.48421L3.04907 4.50158L3.04612 4.50454Z"
        fill={fill}
      />
    </>
  );
};

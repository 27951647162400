import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { useLocalStorage } from "react-use";
import { Col } from "../components/Col";
import { Loader } from "../components/Loader";
import { Typography } from "../components/Typography";

export interface AuthContextValues {
  accessToken: string;
}

const AuthContext = createContext<AuthContextValues | undefined>(undefined);

export const AuthProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [accessTokenState, setAccessTokenState] = useLocalStorage<TokenState>(
    "ACCESS_TOKEN",
    {
      type: TokenStateType.NOT_SET,
    }
  );

  useEffect(() => {
    // Need to add to both window and document to work with both ios and android

    // iOS
    window.addEventListener("message", (event) =>
      handleMessage(event, (value) => setAccessTokenState(value))
    );

    // Android
    document.addEventListener("message", (event) =>
      handleMessage(event, (value) => setAccessTokenState(value))
    );
  }, [setAccessTokenState]);

  if (!accessTokenState) {
    return <Loader size="FULLSCREEN" />;
  }

  if (accessTokenState.type === TokenStateType.VALID) {
    return (
      <AuthContext.Provider value={{ accessToken: accessTokenState.token }}>
        <Col style={{ flexGrow: 1 }}>{children}</Col>
      </AuthContext.Provider>
    );
  }

  if (accessTokenState.type === TokenStateType.INVALID) {
    return <Typography text={`Invalid access token`} />;
  }

  return <Loader size="FULLSCREEN" />;
};

export const useAuth = (): AuthContextValues => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuth must be called inside an AuthProvider`);
  }

  return context;
};

enum TokenStateType {
  VALID = "VALID",
  INVALID = "INVALID",
  NOT_SET = "NOT_SET",
}

interface ValidTokenState {
  type: TokenStateType.VALID;
  token: string;
}

interface InvalidTokenState {
  type: TokenStateType.INVALID;
}

interface NotSetTokenState {
  type: TokenStateType.NOT_SET;
}

type TokenState = ValidTokenState | InvalidTokenState | NotSetTokenState;

type KnownMessage = { method: "injectToken"; params: { token: string } };
type UnknownMessage = { method?: string; params?: { token?: string } };

type Message = KnownMessage | UnknownMessage;
const messageIsKnownMessage = (
  message: UnknownMessage
): message is KnownMessage => {
  return (
    message.method === "injectToken" && message.params?.token !== undefined
  );
};

const handleMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any,
  setAccessTokenState: (newState: TokenState) => void
) => {
  if (
    (typeof event.data === "string" && event.data.includes("webpack")) ||
    (event.data.type && event.data.type.includes("webpack"))
  ) {
    return;
  }
  const message: Message = JSON.parse(event.data);

  if (!messageIsKnownMessage(message)) {
    return setAccessTokenState({ type: TokenStateType.INVALID });
  }

  const { token } = message.params;

  setAccessTokenState({
    token,
    type: TokenStateType.VALID,
  });
};

import { useState } from "react";
import { useQuery, useMutation, ApolloError } from "@apollo/client";

import {
  ChargingLocationDocument,
  JedlixChargingLocation,
  UpdateChargingLocationDocument,
  UpdateJedlixChargingLocationInput,
} from "../../../generated/graphql/graphql";
import { ChargingLocation, toChargingLocation } from "../types/common";

interface UseChargingLocationResponse {
  chargingLocation: ChargingLocation | null;
  queryLoading: boolean;
  queryError: ApolloError | undefined;
  mutationLoading: boolean;
  mutationError: ApolloError | undefined;
  updateChargingLocation: (input: UpdateJedlixChargingLocationInput) => void;
}

const useChargingLocation = (
  chargingLocationId: string | undefined
): UseChargingLocationResponse => {
  const [chargingLocation, setChargingLocation] =
    useState<ChargingLocation | null>(null);

  const { loading: queryLoading, error: queryError } = useQuery(
    ChargingLocationDocument,
    {
      variables: {
        input: { chargingLocationId: chargingLocationId as string },
      },
      skip: !chargingLocationId,
      onCompleted: (data) => {
        const jedlixLocation = data?.jedlixChargingLocation.chargingLocation;

        if (!jedlixLocation) {
          return;
        }
        setChargingLocation(
          toChargingLocation(jedlixLocation as JedlixChargingLocation)
        );
      },
    }
  );

  const [
    updateChargingLocation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UpdateChargingLocationDocument, {
    onCompleted: (data) => {
      const jedlixLocation = data?.updateJedlixChargingLocation;

      if (!jedlixLocation) {
        return;
      }
      setChargingLocation(
        toChargingLocation(jedlixLocation as JedlixChargingLocation)
      );
    },
  });

  const update = async (input: UpdateJedlixChargingLocationInput) => {
    await updateChargingLocation({ variables: { input } });
  };

  return {
    chargingLocation,
    queryError,
    queryLoading,
    mutationLoading,
    mutationError,
    updateChargingLocation: update,
  };
};

export default useChargingLocation;

import { PropsWithChildren } from "react";
import {
  Col,
  ErrorText,
  Header,
  HorizontalAlignment,
  Spacing,
} from "../../components";

interface ErrorScreenProps {
  headerText?: string;
  errorText?: string;
  childSpacing?: Spacing;
}

export const ErrorScreen = ({
  headerText,
  errorText,
  childSpacing = Spacing.TINY,
}: PropsWithChildren<ErrorScreenProps>): JSX.Element => {
  return (
    <Col
      $childSpacing={childSpacing}
      $horizontalAlignChildren={HorizontalAlignment.CENTER}
      style={{ width: "100%" }}
    >
      {headerText && <Header text={headerText} />}

      <ErrorText text={errorText ? errorText : "Kunne ikke laste innhold"} />
    </Col>
  );
};

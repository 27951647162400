import {
  ChargeSettings as GqlChargeSettings,
  JedlixChargeState,
  JedlixVehicle as GqlJedlixVehicle,
  VehicleInfo as JedlixVehicleInfo,
  VehicleChargingStatus as JedlixVehicleChargingStatus,
  JedlixChargingLocation as GqlJedlixChargingLocation,
  LatLng as GqlLatLng,
  JedlixAddress as GqlJedlixAddress,
} from "../../../generated/graphql/graphql";

interface VehicleInfo {
  batteryCapacity: number;
  brand: string;
  model: string;
  range: number;
  version: string;
}

interface VehicleCapabilities {
  charge: boolean;
  chargeState: boolean;
  desiredStateOfCharge: boolean;
  geoLocation: boolean;
  pullTelemetry: boolean;
  startStopCharging: boolean;
  stateOfCharge: boolean;
}

export interface Vehicle {
  capabilities: VehicleCapabilities;
  chargeState: ChargeState | null;
  details: VehicleInfo | null;
  id: string;
  isConnectable: boolean;
  isConnected: boolean;
}

interface ChargeState {
  batteryLevel: number | null;
  range: number | null;
  chargingStatus: VehicleChargingStatus;
  chargingLocationId: string | null;
  updatedAt: Date | null;
  chargePower: number | null;
}

enum VehicleChargingStatus {
  UNKNOWN = "UNKNOWN",
  COMPLETE = "COMPLETE",
  CHARGING = "CHARGING",
  DISCONNECTED = "DISCONNECTED",
  STOPPED = "STOPPED",
}

export interface ChargeSettings {
  id: string;
  vehicleId: string;
  departureTimes: DepartureTimes;
  isSmartChargingEnabled: boolean;
  directChargingBatteryLevel: number;
  desiredBatteryLevel: number;
}

export interface DepartureTimes {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  departureTimeOverride: Date | null;
}

export type VehiclesArray = Vehicle[];
export function toChargeSettings(
  jedlixChargeSettings: GqlChargeSettings
): ChargeSettings {
  const {
    departureTimes,
    id,
    vehicleId,
    isSmartChargingEnabled,
    directChargingBatteryLevel,
    desiredBatteryLevel,
  } = jedlixChargeSettings;

  return {
    id: id,
    vehicleId: vehicleId,
    departureTimes: {
      ...departureTimes,
      departureTimeOverride: departureTimes.departureTimeOverride
        ? new Date(departureTimes.departureTimeOverride)
        : null,
    },
    isSmartChargingEnabled: isSmartChargingEnabled,
    directChargingBatteryLevel: directChargingBatteryLevel,
    desiredBatteryLevel: desiredBatteryLevel,
  };
}

export interface ChargingLocation extends ChargingLocationBase {
  id: string;
  userId: string;
}

export interface ChargingLocationBase {
  address: Address;
  latLng: LatLng;
}

export interface Address {
  city?: string;
  country: string;
  houseNumber?: string;
  postalCode: string;
  street?: string;
}

export interface LatLng {
  latitude: number;
  longitude: number;
}

export function toChargingLocations(
  jedlixChargingLocations: GqlJedlixChargingLocation[]
): ChargingLocation[] {
  return jedlixChargingLocations.map((chargingLocation) => {
    const { address, coordinates, id, userId } = chargingLocation;

    return {
      address: toAddress(address),
      latLng: toLatLng(coordinates),
      postalCode: address.postalCode,
      id: id,
      userId: userId,
    };
  });
}

export function toLatLng(jedlixLatLng: GqlLatLng): LatLng {
  const { latitude, longitude } = jedlixLatLng;

  return {
    latitude: latitude,
    longitude: longitude,
  };
}

export function toChargingLocation(
  chargingLocation: GqlJedlixChargingLocation
): ChargingLocation {
  const { address, coordinates, id, userId } = chargingLocation;
  return {
    address: toAddress(address),
    latLng: toLatLng(coordinates),
    id,
    userId,
  };
}

export function toAddress(jedlixAddress: GqlJedlixAddress): Address {
  const { city, country, houseNumber, postalCode, street } = jedlixAddress;

  return {
    city: city ?? undefined,
    country: country,
    houseNumber: houseNumber ?? undefined,
    postalCode: postalCode,
    street: street ?? undefined,
  };
}

export function toVehicles(jedlixVehicles: GqlJedlixVehicle[]): Vehicle[] {
  return jedlixVehicles.map((vehicle) => toVehicle(vehicle));
}

export function toVehicle(jedlixVehicle: GqlJedlixVehicle): Vehicle {
  const { id, capabilities, chargeState, details, isConnectable, isConnected } =
    jedlixVehicle;

  return {
    id: id,
    capabilities,
    details: toDetails(details as JedlixVehicleInfo | null),
    chargeState: toChargeState(chargeState as JedlixChargeState | null),
    isConnectable,
    isConnected,
  };
}

function toChargeState(
  chargeState: JedlixChargeState | null
): ChargeState | null {
  if (!chargeState) {
    return null;
  }

  return {
    batteryLevel: chargeState.batteryLevel,
    range: chargeState.range,
    chargingStatus: toChargingStatus(chargeState.chargingStatus),
    chargingLocationId: chargeState.chargingLocationId || null,
    updatedAt: new Date(chargeState.updatedAt),
    chargePower: chargeState.chargePower,
  };
}

function toDetails(vehicleInfo: JedlixVehicleInfo | null): VehicleInfo | null {
  if (!vehicleInfo) {
    return null;
  }

  return {
    ...vehicleInfo,
  };
}

function toChargingStatus(
  status: JedlixVehicleChargingStatus
): VehicleChargingStatus {
  switch (status) {
    case "CHARGING":
      return VehicleChargingStatus.CHARGING;
    case "COMPLETE":
      return VehicleChargingStatus.COMPLETE;
    case "DISCONNECTED":
      return VehicleChargingStatus.DISCONNECTED;
    case "STOPPED":
      return VehicleChargingStatus.STOPPED;
    case "UNKNOWN":
      return VehicleChargingStatus.UNKNOWN;
  }
}

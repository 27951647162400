import styled from "styled-components";
import { Label } from "./Label";

export enum InputType {
  STRING,
  NUMBER,
}

interface Props {
  label?: string;
  id: string;
  focus?: boolean;
  type?: InputType;
  onChange: (newValue: string) => void;
  value: string;
}

export const Input = ({
  label,
  id,
  focus = false,
  value,
  type = InputType.STRING,
  onChange,
}: Props): JSX.Element => {
  return (
    <InputContainer>
      {label && <Label text={label} />}
      <StyledInput
        id={id}
        value={value}
        autoFocus={focus}
        type={type === InputType.NUMBER ? "number" : "text"}
        onChange={(event) => onChange(event.target.value)}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  justify-self: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledInput = styled.input`
  background: ${(props) => props.theme.colors.background[500]};
  border: 0;
  height: 2.5rem;
  color: ${(props) => props.theme.colors.text[500]};
  padding: 8px 12px;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  border-radius: 0.25rem;
`;

import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const SettingsIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];

  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10.3923L12 5.19615L9 2.62268e-07L3 0L0 5.19615L3 10.3923H9ZM6 7.5C7.24264 7.5 8.25 6.49264 8.25 5.25C8.25 4.00736 7.24264 3 6 3C4.75736 3 3.75 4.00736 3.75 5.25C3.75 6.49264 4.75736 7.5 6 7.5Z"
        fill={fill}
      />
    </>
  );
};

import { useState } from "react";
import { Header } from "../../../components";
import { Typography } from "../../../components/Typography";
import { LocationSelection } from "./LocationSelection";
import { CancelOrConfirm } from "../../../components/CancelOrConfirm";
import { useNavigator } from "../../../hooks";
import { ScreenWrapper } from "../ScreenWrapper";
import { useMutation } from "@apollo/client";
import { CreateChargingLocationDocument } from "../../../generated/graphql/graphql";
import { RouteMap } from "../../Routes";
import { ChargingLocationBase } from "../types/common";

export const CreateChargingLocation = (): JSX.Element => {
  const navigate = useNavigator();
  const [locationInput, setLocationInput] =
    useState<ChargingLocationBase | null>(null);

  const [createChargingLocation, { loading, error }] = useMutation(
    CreateChargingLocationDocument,
    {
      onCompleted: () => {
        navigate.to(RouteMap.integrations.chargingLocations.basePath);
      },
    }
  );

  const handleSave = () => {
    if (locationInput) {
      createChargingLocation({
        variables: {
          input: {
            address: {
              country: "NO",
              postalCode: locationInput.address.postalCode,
            },
            coordinates: {
              latitude: locationInput?.latLng.latitude,
              longitude: locationInput?.latLng.longitude,
            },
          },
        },
      });
    }
  };

  return (
    <ScreenWrapper header={<Header text="Ny ladelokasjon" />}>
      <Typography text="Velg en adresse nærmest mulig der laderen din er. Lokasjonen vil så godkjennes for smartlading." />

      <LocationSelection
        onLocationChanged={setLocationInput}
        initialLocation={locationInput}
      />

      <CancelOrConfirm
        mutationLoading={loading}
        mutationError={error}
        saveDisabled={!locationInput}
        onCancel={() => navigate.back()}
        onSave={handleSave}
      />
    </ScreenWrapper>
  );
};

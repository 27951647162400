import { useParams } from "react-router-dom";
import { ButtonVariant, Header } from "../../../components";
import { Typography } from "../../../components/Typography";
import { CancelOrConfirm } from "../../../components/CancelOrConfirm";
import { useNavigator } from "../../../hooks";
import { ChargingLocationListEntry } from "./ChargingLocationListEntry";
import { useMutation, useQuery } from "@apollo/client";
import {
  ChargingLocation,
  ChargingLocationDocument,
  DeleteChargingLocationDocument,
  JedlixChargingLocation,
} from "../../../generated/graphql/graphql";
import { ScreenWrapper } from "../ScreenWrapper";
import { RouteMap } from "../../Routes";
import { toChargingLocation } from "../types/common";

export const DeleteChargingLocation = (): JSX.Element => {
  const { chargingLocationId } = useParams<{ chargingLocationId: string }>();
  const navigate = useNavigator();

  const { data, loading, error } = useQuery(ChargingLocationDocument, {
    skip: !chargingLocationId,
    variables: { input: { chargingLocationId: chargingLocationId as string } },
  });

  const [
    deleteChargingLocation,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation(DeleteChargingLocationDocument, {
    onCompleted: () => {
      navigate.to(RouteMap.integrations.chargingLocations.basePath);
    },
    variables: { input: { chargingLocationId: chargingLocationId as string } },
  });

  return (
    <ScreenWrapper
      header={<Header text="Slett lokasjon" />}
      queryError={error}
      queryLoading={loading}
    >
      <Typography text="Denne lokasjonen fjernes som et godkjent ladepunkt og smartlading vil ikke lenger skje der." />

      {data?.jedlixChargingLocation.chargingLocation && (
        <ChargingLocationListEntry
          chargingLocation={toChargingLocation(
            data.jedlixChargingLocation
              .chargingLocation as JedlixChargingLocation
          )}
          backgroundColor="DANGER"
        />
      )}

      <CancelOrConfirm
        mutationLoading={deleteLoading}
        mutationError={deleteError}
        saveButtonVariant={ButtonVariant.DANGER}
        saveTextOverride="Bekreft sletting"
        onCancel={() => navigate.back()}
        onSave={() => {
          if (chargingLocationId) {
            deleteChargingLocation();
          }
        }}
        saveDisabled={!chargingLocationId}
      />
    </ScreenWrapper>
  );
};

import styled, { CSSProperties } from "styled-components";
import { propsToTextColor, TextColor, VariantProps } from "../util";

interface Props {
  text: string;
  textColor?: TextColor;
  style?: CSSProperties;
  onClick: () => void;
}

export const LinkButton = ({
  style,
  text,
  textColor = TextColor.PRIMARY,
  onClick,
}: Props): JSX.Element => {
  return (
    <StyledLinkButton $textColor={textColor} style={style} onClick={onClick}>
      {text}
    </StyledLinkButton>
  );
};

const StyledLinkButton = styled.button<VariantProps>`
  color: ${(props) => propsToTextColor(props)};
  align-items: center;
  border: 0;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-variant: small-caps;
`;

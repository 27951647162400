import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const CheckedCheckboxIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.success[500];

  return (
    <>
      <rect width="24" height="24" rx="4" fill={fill} />
      <line
        x1="6.70711"
        y1="11.9179"
        x2="10.7071"
        y2="15.9179"
        stroke="#222222"
        strokeWidth="2"
      />
      <line
        x1="9.29289"
        y1="15.9179"
        x2="17.2929"
        y2="7.91789"
        stroke="#222222"
        strokeWidth="2"
      />
    </>
  );
};

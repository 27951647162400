import { ButtonVariant, Header, Row, Spacing } from "../../../components";
import { useMutation, useQuery } from "@apollo/client";
import {
  DisconnectVehicleDocument,
  JedlixVehicle,
  VehiclesDocument,
} from "../../../generated/graphql/graphql";
import { CheckboxListEntry } from "../../../components/CheckboxListEntry";
import { useState } from "react";
import { vehicleToVehicleName } from "../../../util";
import { Typography } from "../../../components/Typography";
import { useNavigator } from "../../../hooks";
import { ScreenWrapper } from "../ScreenWrapper";
import { CancelOrConfirm } from "../../../components/CancelOrConfirm";
import { toVehicles } from "../types/common";

export const DisconnectVehicle = (): JSX.Element => {
  const [checkedVehicle, setCheckedVehicle] = useState<string>();
  const navigate = useNavigator();

  const { data, loading, error, refetch } = useQuery(VehiclesDocument);

  const vehicles = data?.listJedlixVehicles
    ? toVehicles(data.listJedlixVehicles.vehicles as JedlixVehicle[])
    : null;

  const [
    disconnectVehicle,
    { error: disconnectError, loading: disconnectLoading },
  ] = useMutation(DisconnectVehicleDocument, {
    onCompleted: () => {
      refetch();
    },
    variables: { input: { vehicleId: checkedVehicle as string } },
  });

  const onCancel = () => {
    setCheckedVehicle(undefined);
    navigate.back();
  };

  return (
    <ScreenWrapper
      childSpacing={Spacing.MEDIUM}
      header={<Header text="Fjern integrasjoner" />}
      queryError={error}
      queryLoading={loading}
    >
      <Typography text="Dersom du bekrefter sletting, vil alle smarte koblinger fra leverandøren fjernes - i tillegg til alle innstillinger og ladehistorikk." />

      <Row
        style={{
          justifyContent: "center",
          width: "100%",
        }}
      >
        {vehicles &&
          vehicles.map((vehicle) => {
            return (
              <CheckboxListEntry
                checked={checkedVehicle === vehicle?.id}
                checkedColor="DANGER"
                onClick={() =>
                  setCheckedVehicle(
                    checkedVehicle === vehicle?.id ? undefined : vehicle?.id
                  )
                }
                key={vehicle?.id}
                label={vehicleToVehicleName(vehicle)}
              />
            );
          })}
      </Row>

      <CancelOrConfirm
        saveTextOverride="Bekreft sletting"
        saveButtonVariant={ButtonVariant.DANGER}
        saveDisabled={!checkedVehicle}
        mutationLoading={disconnectLoading}
        mutationError={disconnectError}
        onCancel={onCancel}
        onSave={disconnectVehicle}
      />
    </ScreenWrapper>
  );
};

import { Col, IconType, RoundButton, Row, Spacing, TextAlign } from ".";
import { Typography, TypographyVariant } from "./Typography";
import styled from "styled-components";
import { useNavigator } from "../hooks";

interface Props {
  text: string;
  subHeader?: string;
  buttonRight?: {
    icon: IconType;
    onClick: () => void;
  };
  onHeaderClick?: () => void;
  onBackClick?: () => void;
}

export const Header = ({
  text,
  subHeader,
  buttonRight,
  onHeaderClick,
  onBackClick,
}: Props): JSX.Element => {
  const navigate = useNavigator();

  const handleOnBackClick = () => {
    if (onBackClick) {
      onBackClick();
      return;
    }
    navigate.back();
  };

  return (
    <Col
      $padding={{ horizontal: Spacing.NONE, vertical: Spacing.NONE }}
      $childSpacing={Spacing.TINY}
      style={{
        marginBottom: "1rem",
        width: "100%",
      }}
    >
      <Row
        style={{
          display: "grid",
          gap: "0.5rem",
          gridTemplateColumns: "32px 1fr 32px",
          width: "100%",
        }}
      >
        <RoundButton
          icon={{ type: IconType.BACK, size: 16 }}
          onClick={handleOnBackClick}
        />
        <HeaderContainer onClick={onHeaderClick}>
          <Typography
            text={text}
            emphasized
            variant={TypographyVariant.HEADER}
          />
        </HeaderContainer>
        {buttonRight && (
          <RoundButton
            icon={{ type: buttonRight?.icon }}
            onClick={buttonRight?.onClick}
          />
        )}
      </Row>
      {subHeader && (
        <Typography
          emphasized
          textAlign={TextAlign.CENTER}
          text={subHeader}
          variant={TypographyVariant.SUB_HEADER}
        />
      )}
    </Col>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-self: center;
  align-items: center;
  border: ${(props) => `1px solid ${props.theme.colors.text[200]}`};

  border-radius: 32px;
  height: 32px;
  padding: 2px 24px;
`;

import { BrowserRouter } from "react-router-dom";
import { AppConfigProvider } from "../app-config/AppConfigProvider";
import { AuthProvider } from "../auth/AuthProvider";
import { Col } from "../components/Col";
import { Spacing } from "../components/constraints/Spacing";
import { GraphqlProvider } from "../graphql/GraphqlProvider";
import { ThemeProvider } from "../theme/ThemeProvider";
import { VehicleProvider } from "./jedlix/vehicle/VehicleProvider";
import { Routes } from "./Routes";

function App(): JSX.Element {
  return (
    <Col style={{ alignItems: "center", flexGrow: 1 }}>
      <Col
        style={{
          height: "100%",
          padding: `${Spacing.SMALL} ${Spacing.MEDIUM}`,
          width: "clamp(256px, 100%, 100%)",
        }}
      >
        <BrowserRouter>
          <ThemeProvider>
            <AppConfigProvider>
              <AuthProvider>
                <GraphqlProvider>
                  <VehicleProvider>
                    <Routes />
                  </VehicleProvider>
                </GraphqlProvider>
              </AuthProvider>
            </AppConfigProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Col>
    </Col>
  );
}

export default App;

import { Col, IconType, RoundButton, Row, Spacing } from "../../../components";

import { TextColor } from "../../../util";
import { Typography } from "../../../components/Typography";

interface SmartChargingListEntryProps {
  header: string;
  changes?: string | boolean;
  description: string;
  onClick: () => void;
  disabled?: boolean;
}

export const SmartChargingListEntry = ({
  header,
  changes,
  description,
  onClick,
  disabled,
}: SmartChargingListEntryProps): JSX.Element => {
  const roundButton = (): JSX.Element => {
    if (typeof changes === "string") {
      return (
        <RoundButton
          text={changes}
          big
          backgroundColor={TextColor.LIGHT_SUCCESS}
          onClick={onClick}
          disabled={disabled}
        />
      );
    }
    return (
      <RoundButton
        icon={{
          color: disabled ? TextColor.DISABLED : TextColor.PRIMARY,
          type: changes ? IconType.CHECK : IconType.PLUS,
        }}
        big
        backgroundColor={changes ? TextColor.LIGHT_SUCCESS : TextColor.SUCCESS}
        onClick={onClick}
        disabled={disabled}
      />
    );
  };

  return (
    <Row
      style={{
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "1fr 48px",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Col $childSpacing={Spacing.TINY}>
        <Typography text={header} emphasized />

        <Typography text={description} />
      </Col>
      {roundButton()}
    </Row>
  );
};

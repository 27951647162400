import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const DeleteIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.danger[500];

  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.125 0.375C7.125 0.167893 6.95711 0 6.75 0H5.25C5.04289 0 4.875 0.167893 4.875 0.375V1.125C4.875 1.33211 4.70711 1.5 4.5 1.5H1.5C1.29289 1.5 1.125 1.66789 1.125 1.875V2.625C1.125 2.83211 1.29289 3 1.5 3H10.5C10.7071 3 10.875 2.83211 10.875 2.625V1.875C10.875 1.66789 10.7071 1.5 10.5 1.5H7.5C7.29289 1.5 7.125 1.33211 7.125 1.125V0.375Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 3.75H6.375L6.375 10.125C6.375 10.3321 6.20711 10.5 6 10.5C5.79289 10.5 5.625 10.3321 5.625 10.125L5.625 3.75H4.125L4.125 10.125C4.125 10.3321 3.95711 10.5 3.75 10.5C3.54289 10.5 3.375 10.3321 3.375 10.125L3.375 3.75H1.875V9.75C1.875 10.9926 2.88236 12 4.125 12H7.875C9.11764 12 10.125 10.9926 10.125 9.75V3.75H8.625L8.625 10.125C8.625 10.3321 8.45711 10.5 8.25 10.5C8.04289 10.5 7.875 10.3321 7.875 10.125L7.875 3.75Z"
        fill={fill}
      />
    </>
  );
};

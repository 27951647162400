interface Props {
  id: string;
  checked: boolean;
  onToggled: () => void;
}

import styled from "styled-components";

interface Props {
  id: string;
  checked: boolean;
  onToggled: () => void;
  disabled?: boolean;
}

const toggleWidth = "40px";
const toggleHeight = "20px";

export const Toggle = ({
  id,
  onToggled,
  checked,
  disabled,
}: Props): JSX.Element => {
  return (
    <ToggleContainer
      $toggleWidth={toggleWidth}
      $toggleHeight={toggleHeight}
      disabled={disabled}
    >
      <CheckBox
        $toggleWidth={toggleWidth}
        $toggleHeight={toggleHeight}
        checked={checked}
        onChange={onToggled}
        type="checkbox"
        id={id}
        disabled={disabled}
      />
      <CheckBoxLabel
        htmlFor={id}
        $toggleWidth={toggleWidth}
        $toggleHeight={toggleHeight}
        disabled={disabled}
      />
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div<{
  $toggleHeight: string;
  $toggleWidth: string;
  disabled?: boolean;
}>`
  position: relative;
  width: ${(props) => props.$toggleWidth};
  height: ${(props) => props.$toggleHeight};
  padding-right: 10px;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

const CheckBoxLabel = styled.label<{
  $toggleHeight: string;
  $toggleWidth: string;
  disabled?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.$toggleWidth};
  height: ${(props) => props.$toggleHeight};
  border-radius: 20px;
  background: white;
  cursor: pointer;
  transition: background-color 0.2s;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px; // Knob size
    height: 12px; // Knob size
    background: ${(props) => props.theme.colors.text[500]};
    transition: margin 0.4s; // Smooth slide transition
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%); // Center the knob
  }

  ${(props) =>
    props.disabled &&
    `
    background: gray;
    cursor: not-allowed;
  `}
`;

const CheckBox = styled.input<{
  $toggleHeight: string;
  $toggleWidth: string;
  disabled?: boolean;
}>`
  opacity: 0;
  z-index: 1;
  border-radius: 20px;
  width: ${(props) => props.$toggleWidth};
  height: ${(props) => props.$toggleHeight};

  &:checked + ${CheckBoxLabel} {
    background: ${(props) => props.theme.colors.success[300]};

    &::after {
      margin-left: 17px; // Knob's slide distance
    }
  }

  ${(props) =>
    props.disabled &&
    `
    & + ${CheckBoxLabel} {
      background: gray;
      cursor: not-allowed;
    }
  `}
`;

import { useQuery } from "@apollo/client";
import { ChargingLocationsDocument } from "../../../generated/graphql/graphql";
import { useEffect, useRef, useState } from "react";
import { SmartChargingOverview } from "./SmartChargingOverview";
import { BatteryLevels } from "./BatteryLevels";
import { DepartureTimesSetup } from "./DepartureTimes";
import { ChargingLocationsOverview } from "../charging-locations/ChargingLocationsOverview";
import {
  ChargeSettings,
  ChargingLocation,
  DepartureTimes,
  toChargingLocations,
} from "../types/common";
import { useChargeSettings } from "./ChargeSettingsHook";
import { useVehicle } from "../vehicle/VehicleProvider";
import { vehicleToVehicleName } from "../../../util";
import { ErrorScreen } from "../ErrorScreen";
import { useNavigator } from "../../../hooks";
import { RouteMap } from "../../Routes";

export interface SmartChargingBaseProps {
  header: string;
  subHeader: string;
}

enum SmartChargingScreen {
  OVERVIEW = "Oppsett av smartlading",
  DIRECT_BATTERY_LEVELS = "Batterinivåer",
  DESIRED_BATTERY_LEVELS = "batterinivåer",
  DEPARTURE_TIMES = "Ukeplan for tidsfrister",
  CHARGING_LOCATIONS = "Ladelokasjoner",
}

export const ConfigureSmartCharging = (): JSX.Element => {
  const { vehicle } = useVehicle();
  const navigate = useNavigator();

  const {
    chargeSettings,
    loadingQuery: loadingChargeSettings,
    loadingMutation: loadingMutationChargeSettings,
    errorQuery: errorQueryChargeSettings,
    errorMutation: errorMutationChargeSettings,
    create,
    update,
  } = useChargeSettings(vehicle?.id);

  const [chargeSettingsInput, setChargeSettingsInput] =
    useState<Partial<ChargeSettings> | null>(null);

  useEffect(() => {
    if (chargeSettings) {
      setChargeSettingsInput(chargeSettings);
    }
  }, [chargeSettings]);

  const [chargingLocations, setChargingLocations] = useState<
    ChargingLocation[] | null
  >();

  const { loading: loadingChargingLocations, error: errorChargingLocations } =
    useQuery(ChargingLocationsDocument, {
      onCompleted: (data) => {
        setChargingLocations(
          toChargingLocations(data.jedlixChargingLocations.chargingLocations)
        );
      },
    });

  const [screenOpen, setScreenOpen] = useState<SmartChargingScreen>(
    SmartChargingScreen.OVERVIEW
  );

  const onSave = () => {
    if (!chargeSettingsInput) {
      return;
    }

    if (chargeSettings !== null) {
      update({
        ...(chargeSettingsInput as ChargeSettings),
        timeZone: "EUROPE_OSLO",
        isSolarChargingEnabled: false,
        isSmartChargingEnabled: true,
        maxAllowedTargetSoC: 100,
      });
    } else {
      create({
        ...(chargeSettingsInput as ChargeSettings),
        timeZone: "EUROPE_OSLO",
        isSolarChargingEnabled: false,
        isSmartChargingEnabled: true,
        maxAllowedTargetSoC: 100,
      });
    }
  };

  const onDirectBatteryLevelInputChange = (value: number | undefined) => {
    setChargeSettingsInput({
      ...chargeSettingsInput,
      directChargingBatteryLevel: value,
    });
  };

  const onDesiredBatteryLevelInputChange = (value: number | undefined) => {
    setChargeSettingsInput({
      ...chargeSettingsInput,
      desiredBatteryLevel: value,
    });
  };

  const onDepartureTimesInputChange = (value: DepartureTimes | undefined) => {
    setChargeSettingsInput({
      ...chargeSettingsInput,
      departureTimes: value,
    });
  };

  if (!vehicle) {
    return <ErrorScreen headerText="Bil" errorText="Kunne ikke finne bilen" />;
  }

  const screen = (screenOpen: SmartChargingScreen): JSX.Element => {
    switch (screenOpen) {
      case SmartChargingScreen.DIRECT_BATTERY_LEVELS:
        return (
          <BatteryLevels
            header={vehicleToVehicleName(vehicle)}
            subHeader={screenOpen}
            directBatteryLevel={chargeSettingsInput?.directChargingBatteryLevel?.toString()}
            desiredBatteryLevel={chargeSettingsInput?.desiredBatteryLevel?.toString()}
            onDirectBatteryLevelChange={(value: number | undefined) =>
              onDirectBatteryLevelInputChange(value)
            }
            onDesiredBatteryLevelChange={(value: number | undefined) =>
              onDesiredBatteryLevelInputChange(value)
            }
            directFocus
            goToOverview={() => setScreenOpen(SmartChargingScreen.OVERVIEW)}
          />
        );

      case SmartChargingScreen.DESIRED_BATTERY_LEVELS:
        return (
          <BatteryLevels
            header={vehicleToVehicleName(vehicle)}
            subHeader={screenOpen}
            directBatteryLevel={chargeSettingsInput?.directChargingBatteryLevel?.toString()}
            desiredBatteryLevel={chargeSettingsInput?.desiredBatteryLevel?.toString()}
            onDirectBatteryLevelChange={(value: number | undefined) =>
              onDirectBatteryLevelInputChange(value)
            }
            onDesiredBatteryLevelChange={(value: number | undefined) =>
              onDesiredBatteryLevelInputChange(value)
            }
            desiredFocus
            goToOverview={() => setScreenOpen(SmartChargingScreen.OVERVIEW)}
          />
        );
      case SmartChargingScreen.DEPARTURE_TIMES:
        return (
          <DepartureTimesSetup
            header={vehicleToVehicleName(vehicle)}
            subHeader={screenOpen}
            departureTimes={chargeSettingsInput?.departureTimes}
            onDepartureTimesChange={(value: DepartureTimes | undefined) =>
              onDepartureTimesInputChange(value)
            }
            goToOverview={() => setScreenOpen(SmartChargingScreen.OVERVIEW)}
          />
        );

      case SmartChargingScreen.CHARGING_LOCATIONS:
        return (
          <ChargingLocationsOverview
            onBackClickOverride={() =>
              navigate.to(
                RouteMap.integrations.vehicle.smartCharging.createPathForVehicleId(
                  vehicle.id
                )
              )
            }
          />
        );

      default:
        return (
          <SmartChargingOverview
            header={vehicleToVehicleName(vehicle)}
            subHeader={screenOpen}
            onSave={() => onSave()}
            queryLoading={loadingChargingLocations || loadingChargeSettings}
            queryError={errorChargingLocations || errorQueryChargeSettings}
            mutationLoading={loadingMutationChargeSettings}
            mutationError={errorMutationChargeSettings}
            setDirectBatteryLevelsOpen={() =>
              setScreenOpen(SmartChargingScreen.DIRECT_BATTERY_LEVELS)
            }
            setDesiredBatteryLevelsOpen={() =>
              setScreenOpen(SmartChargingScreen.DESIRED_BATTERY_LEVELS)
            }
            setDepartureTimesOpen={() =>
              setScreenOpen(SmartChargingScreen.DEPARTURE_TIMES)
            }
            chargeSettingsInput={chargeSettingsInput as ChargeSettings}
            chargingLocations={chargingLocations ?? []}
          />
        );
    }
  };

  return <>{screen(screenOpen)}</>;
};

import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBackgroundColor,
  Icon,
  IconType,
  Loader,
  Spacing,
} from "../../../components";
import { Typography } from "../../../components/Typography";
import { geocodeByLatLng } from "react-google-places-autocomplete";
import { ChargingLocation } from "../types/common";

interface ChargingLocationListEntryProps {
  chargingLocation?: ChargingLocation;
  backgroundColor?: CardBackgroundColor;
  onClick?: () => void;
}

export const ChargingLocationListEntry = ({
  chargingLocation,
  backgroundColor = "SUCCESS",
  onClick,
}: ChargingLocationListEntryProps): JSX.Element => {
  const [address, setAddress] = useState<string>();
  const hasBeenInitiatedRef = useRef(false);

  useEffect(() => {
    const getAddress = async () => {
      if (chargingLocation) {
        const result = await geocodeByLatLng({
          lat: chargingLocation.latLng.latitude,
          lng: chargingLocation.latLng.longitude,
        });
        const [firstResult] = result;

        setAddress(firstResult.formatted_address);
      }
    };

    if (hasBeenInitiatedRef.current === false) {
      getAddress();
      hasBeenInitiatedRef.current = true;
    }
  }, [hasBeenInitiatedRef]);

  return (
    <Card
      $padding={{ horizontal: Spacing.SMALL, vertical: Spacing.TINY }}
      $backgroundColor={backgroundColor}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 16px",
        justifyContent: "center",
        minHeight: "40px",
        width: "100%",
      }}
      onClick={onClick}
    >
      {!address ? <Loader /> : <Typography text={address} centerVertically />}

      {onClick && <Icon type={IconType.EDIT} size={16} centerVertically />}
    </Card>
  );
};

import { Row, Spacing } from "../../components";
import { ChargeStateIcon } from "../../components/icon/chargeStateIcons/ChargeStateIcon";
import { ChargeStateIconType } from "../../components/icon/chargeStateIcons/ChargeStateIconType";
import { Typography, TypographyVariant } from "../../components/Typography";
import { VehicleChargingStatus } from "../../generated/graphql/graphql";
import { ErrorScreen } from "./ErrorScreen";
import { Vehicle } from "./types/common";

interface Props {
  vehicle: Vehicle | null;
}

export const ChargingStatus = ({ vehicle }: Props): JSX.Element => {
  if (!vehicle) {
    return <ErrorScreen />;
  }

  const { icon, status } = toChargingStatus(
    vehicle.chargeState?.chargingStatus
  );

  return (
    <Row
      style={{
        alignItems: "center",
        gap: Spacing.TINY,
        justifyContent: "space-between",
      }}
    >
      <ChargeStateIcon type={icon} size={11} />
      <Typography text={status} emphasized variant={TypographyVariant.DETAIL} />
    </Row>
  );
};

const toChargingStatus = (
  chargeState?: VehicleChargingStatus | null
): { icon: ChargeStateIconType; status: string } => {
  switch (chargeState) {
    case "CHARGING":
      return { icon: ChargeStateIconType.CHARGING, status: "Lader nå" };
    case "COMPLETE":
      return {
        icon: ChargeStateIconType.FULLY_CHARGED,
        status: "Lading fullført",
      };
    case "DISCONNECTED":
      return {
        icon: ChargeStateIconType.NOT_PLUGGED_IN,
        status: "Ikke tilkoblet lader",
      };
    case "STOPPED":
      return { icon: ChargeStateIconType.PAUSED, status: "Lading stoppet" };
    default:
      return {
        icon: ChargeStateIconType.NOT_REACHABLE,
        status: "Ukjent ladestatus",
      };
  }
};

import styled, { DefaultTheme } from "styled-components";
import { mixins } from "./mixins";
import { Padding } from "./mixins/Padding";

interface Props {
  $backgroundColor?: CardBackgroundColor;
  $padding?: Padding;
}

export const Card = styled.div<Props>`
  border-radius: 4px;

  ${(props) => mixins.padding(props.$padding)}

  overflow: hidden;

  ${({ onClick }) =>
    onClick &&
    "box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.15), 1px 1px 1px rgba(0, 0, 0, 0.15);"}

  background: ${(props) =>
    props.$backgroundColor
      ? cardBackgroundColorToColor({
          backgroundColor: props.$backgroundColor,
          theme: props.theme,
        })
      : props.theme.colors.background[500]};
`;

const cardBackgroundColorToColor = ({
  backgroundColor,
  theme,
}: {
  backgroundColor: CardBackgroundColor;
  theme: DefaultTheme;
}) => {
  if (backgroundColor === "SUCCESS") {
    return theme.colors.success["300"];
  }
  if (backgroundColor === "DANGER") {
    return theme.colors.danger["300"];
  }
  return theme.colors.background["500"];
};

export type CardBackgroundColor = "NORMAL" | "SUCCESS" | "DANGER";

import { useQuery, useMutation } from "@apollo/client";
import { useMemo, useState } from "react";
import {
  CreateChargeSettingsDocument,
  CreateChargeSettingsInput,
  GetChargeSettingsDocument,
  UpdateChargeSettingsDocument,
  UpdateChargeSettingsInput,
} from "../../../generated/graphql/graphql";
import { ChargeSettings, toChargeSettings } from "../types/common";

export const useChargeSettings = (vehicleId: string | undefined) => {
  const [chargeSettings, setChargeSettings] = useState<ChargeSettings | null>(
    null
  );

  const [createChargeSettings, { loading: loadingCreate, error: errorCreate }] =
    useMutation(CreateChargeSettingsDocument, {
      onCompleted: (data) => {
        setChargeSettings(
          toChargeSettings(data.createChargeSettings.chargeSettings)
        );
      },
    });
  const [updateChargeSettings, { loading: loadingUpdate, error: errorUpdate }] =
    useMutation(UpdateChargeSettingsDocument, {
      onCompleted: (data) => {
        setChargeSettings(
          toChargeSettings(data.updateChargeSettings.chargeSettings)
        );
      },
    });

  const {
    loading: loadingQuery,
    error: errorQuery,
    refetch,
  } = useQuery(GetChargeSettingsDocument, {
    skip: !vehicleId,
    onCompleted: (data) => {
      setChargeSettings(
        toChargeSettings(data.getChargeSettings.chargeSettings)
      );
    },
    variables: { input: { vehicleId: vehicleId as string } },
  });

  const chargeSettingsChanged = useMemo(() => {
    return chargeSettings;
  }, [chargeSettings]);

  const create = async (input: CreateChargeSettingsInput) => {
    await createChargeSettings({ variables: { input } });
  };

  const update = async (input: UpdateChargeSettingsInput) => {
    await updateChargeSettings({ variables: { input } });
  };

  if (!vehicleId) {
    return {
      chargeSettings: null,
      create,
      errorMutation: errorUpdate || errorCreate,
      errorQuery: undefined,
      loadingMutation: loadingCreate || loadingUpdate,
      loadingQuery: false,
      refetch,
      update,
    };
  }

  return {
    chargeSettings: chargeSettingsChanged,
    create,
    errorMutation: errorUpdate || errorCreate,
    errorQuery,
    loadingMutation: loadingCreate || loadingUpdate,
    loadingQuery,
    refetch,
    update,
  };
};

import { useEffect, useState } from "react";
import {
  CheckboxListEntry,
  Header,
  Spacing,
  TimeInput,
} from "../../../components";
import { SmartChargingBaseProps } from "./ConfigureSmartCharging";
import { DeadlinesForDaysOfWeek } from "./DeadlinesForDaysOfWeek";
import { TextColor } from "../../../util";
import { CancelOrConfirm } from "../../../components/CancelOrConfirm";
import { ScreenWrapper } from "../ScreenWrapper";
import { DepartureTimes } from "../types/common";

interface DepartureTimesProps extends SmartChargingBaseProps {
  departureTimes?: DepartureTimes;
  onDepartureTimesChange: (value: DepartureTimes | undefined) => void;
  goToOverview: () => void;
}

export const DepartureTimesSetup = ({
  header,
  subHeader,
  departureTimes,
  onDepartureTimesChange,
  goToOverview,
}: DepartureTimesProps): JSX.Element => {
  const [sameAllDays, setSameAllDays] = useState<boolean>(
    sameDepartureTimeEachDay(departureTimes)
  );

  const [overrideTimeString, setOverrideTimeString] = useState(
    // existingChargeDeadline
    //   ? convertUtcTimeStringToLocalTimeString(existingChargeDeadline)
    //   :
    departureTimes ? departureTimes.monday : "07:00"
  );

  useEffect(() => {
    if (sameAllDays) {
      onDepartureTimesChange({
        monday: overrideTimeString,
        tuesday: overrideTimeString,
        wednesday: overrideTimeString,
        thursday: overrideTimeString,
        friday: overrideTimeString,
        saturday: overrideTimeString,
        sunday: overrideTimeString,
        departureTimeOverride: null,
      });
    }
  }, [overrideTimeString, sameAllDays]);

  const handleOnCancelClick = () => {
    onDepartureTimesChange(undefined);
    goToOverview();
  };
  return (
    <ScreenWrapper
      childSpacing={Spacing.SMALL}
      header={
        <Header
          text={header}
          subHeader={subHeader}
          onBackClick={goToOverview}
        />
      }
    >
      <CheckboxListEntry
        checked={sameAllDays}
        checkedColor="SUCCESS"
        label="Samme alle dager"
        contentRight={
          <TimeInput
            value={overrideTimeString}
            disabled={!sameAllDays}
            backgroundColor={
              !sameAllDays ? TextColor.LIGHT_SUCCESS : TextColor.SUCCESS
            }
            color={!sameAllDays ? TextColor.LIGHT_PRIMARY : TextColor.PRIMARY}
            onValueChanged={setOverrideTimeString}
          />
        }
        onClick={() => setSameAllDays(true)}
      />

      <CheckboxListEntry
        checked={!sameAllDays}
        checkedColor="SUCCESS"
        label="Ulike dagsfrister"
        onClick={() => setSameAllDays(false)}
      />

      <DeadlinesForDaysOfWeek
        disabled={sameAllDays}
        departureTimes={departureTimes}
        onDepartureTimesChange={onDepartureTimesChange}
      />

      <CancelOrConfirm
        onCancel={handleOnCancelClick}
        saveTextOverride="Bekreft"
        onSave={goToOverview}
      />
    </ScreenWrapper>
  );
};

const sameDepartureTimeEachDay = (
  departureTimes: DepartureTimes | undefined
) => {
  if (!departureTimes) {
    return true;
  }
  const isSame = Object.values(departureTimes).map(
    (val) => val === departureTimes.monday
  );
  return !isSame.includes(false);
};

import { ErrorText, Header, Spacing } from "../../../components";
import { SmartChargingBaseProps } from "./ConfigureSmartCharging";
import { SmartChargingListEntry } from "./SmartChargingListEntry";
import { RouteMap } from "../../Routes";
import { CancelOrConfirm } from "../../../components/CancelOrConfirm";
import { useNavigator } from "../../../hooks";
import { ScreenWrapper } from "../ScreenWrapper";
import { ChargeSettings, ChargingLocation } from "../types/common";
import { ApolloError } from "@apollo/client";

interface SmartChargingOverviewProps extends SmartChargingBaseProps {
  setDirectBatteryLevelsOpen: () => void;
  setDesiredBatteryLevelsOpen: () => void;
  setDepartureTimesOpen: () => void;
  onSave: () => void;
  chargeSettingsInput: Partial<ChargeSettings> | null;
  chargingLocations: ChargingLocation[];
  mutationLoading: boolean;
  mutationError?: ApolloError;
  queryLoading: boolean;
  queryError?: ApolloError;
}

export const SmartChargingOverview = ({
  header,
  subHeader,
  chargeSettingsInput,
  chargingLocations,
  mutationLoading,
  mutationError,
  queryLoading,
  queryError,
  onSave,
  setDirectBatteryLevelsOpen,
  setDesiredBatteryLevelsOpen,
  setDepartureTimesOpen,
}: SmartChargingOverviewProps): JSX.Element => {
  const navigate = useNavigator();

  return (
    <ScreenWrapper
      childSpacing={Spacing.SMALL}
      header={<Header text={header} subHeader={subHeader} />}
      queryError={queryError}
      queryLoading={queryLoading}
    >
      {mutationError && <ErrorText text={"Could not handle request"} />}
      <SmartChargingListEntry
        header="Ladelokasjoner (kreves)"
        changes={
          chargingLocations.length > 0
            ? chargingLocations.length.toString()
            : undefined
        }
        description="Godkjente lokasjoner der smartlading vil settes i gang."
        onClick={() =>
          chargingLocations.length > 0
            ? navigate.to(RouteMap.integrations.chargingLocations.basePath)
            : navigate.to(RouteMap.integrations.chargingLocations.add)
        }
      />
      <div style={{ borderTop: "1px solid black", width: "100%" }} />
      <SmartChargingListEntry
        header="Direktelading"
        changes={
          chargeSettingsInput?.directChargingBatteryLevel != null
            ? `${chargeSettingsInput.directChargingBatteryLevel} %`
            : undefined
        }
        disabled={chargingLocations.length === 0}
        description="Lader alltid opp til dette nivået før smartlading begynner"
        onClick={setDirectBatteryLevelsOpen}
      />
      <SmartChargingListEntry
        header="Målnivå for smarte økter"
        changes={
          chargeSettingsInput?.desiredBatteryLevel != null
            ? `${chargeSettingsInput.desiredBatteryLevel} %`
            : undefined
        }
        disabled={chargingLocations.length === 0}
        description="Smartlading tilpasses dette nivået, og ladeøkten stoppes når det nås."
        onClick={setDesiredBatteryLevelsOpen}
      />
      <SmartChargingListEntry
        header="Ukeplan for ladefrister (kreves)"
        changes={chargeSettingsInput?.departureTimes != null}
        description="Frist for ønsket ladenivå settes for hver dag i uken."
        onClick={setDepartureTimesOpen}
        disabled={chargingLocations.length === 0}
      />
      <CancelOrConfirm
        onCancel={() => {
          navigate.back();
        }}
        mutationLoading={mutationLoading}
        saveDisabled={chargingLocations.length === 0}
        onSave={onSave}
      />
    </ScreenWrapper>
  );
};

import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const FinishIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.success[500];

  return (
    <>
      <rect
        width="3"
        height="3"
        rx="0.75"
        transform="matrix(-1 0 0 1 6 6)"
        fill={fill}
      />
      <rect
        width="3"
        height="3"
        rx="0.75"
        transform="matrix(-1 0 0 1 6 0)"
        fill={fill}
      />
      <path
        d="M3 9.75C3 9.33579 2.66421 9 2.25 9H0.75C0.335786 9 0 9.33579 0 9.75V11.25C0 11.6642 0.335786 12 0.75 12H2.25C2.66421 12 3 11.6642 3 11.25V9.75Z"
        fill={fill}
      />
      <rect
        width="3"
        height="3"
        rx="0.75"
        transform="matrix(-1 0 0 1 3 3)"
        fill={fill}
      />
    </>
  );
};

import styled, { DefaultTheme } from "styled-components";
import { Row, TextAlign } from "../../components";
import { Typography } from "../../components/Typography";
import { VehicleChargingStatus } from "../../generated/graphql/graphql";
import { ErrorScreen } from "./ErrorScreen";

const LOW_BATTERY_LEVEL = 20;

interface Props {
  batteryChargeState: VehicleChargingStatus;
  progress?: number;
  range?: number;
  gradient?: boolean;
  height?: string;
  width?: string;
}

export const BatteryProgressBar = ({
  batteryChargeState: chargeState,
  height,
  width,
  progress,
  range,
  gradient,
}: Props): JSX.Element => {
  if (progress == null) {
    return <ErrorScreen />;
  }
  const text = `${progress} % - ${range} km`;

  return (
    <Row
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Container $height={height} $width={width} $chargeState={chargeState}>
        <Typography
          text={text}
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            position: "absolute",
            width: "100%",
          }}
          textAlign={TextAlign.CENTER}
        />
        <Progress $batteryLevel={progress} $gradient={gradient} />
      </Container>

      <BatteryPlusSide />
    </Row>
  );
};

const Container = styled.div<{
  $height?: string;
  $width?: string;
  $chargeState: VehicleChargingStatus;
}>`
  position: relative;
  width: ${(props) => props.$width ?? "100%"};
  height: ${(props) => props.$height ?? "24px"};

  border: 1px solid ${(props) => `${props.theme.colors.text[500]}`};

  background: ${({ $chargeState: chargeState, theme }) =>
    batteryChargeStateToColor({ chargeState, theme })};
  border-radius: 4px;
`;

const Progress = styled.div<{
  $batteryLevel?: number;
  $gradient?: boolean;
}>`
  width: ${({ $batteryLevel: batteryLevel }) => batteryLevel}%;
  height: 100%;
  background: ${({ $batteryLevel: batteryLevel, theme }) =>
    batteryLevelToColor({ batteryLevel, theme })};
  border-radius: 2px 1px 1px 2px;
`;

const BatteryPlusSide = styled.div`
  height: 45%;
  width: 2px;
  background-color: black;
`;

const batteryLevelToColor = ({
  batteryLevel,
  theme,
}: {
  batteryLevel?: number;
  theme: DefaultTheme;
}): string => {
  if (batteryLevel && batteryLevel < LOW_BATTERY_LEVEL) {
    return theme.colors.danger[500];
  }
  return theme.colors.success[500];
};

const batteryChargeStateToColor = ({
  chargeState,
  theme,
}: {
  chargeState: VehicleChargingStatus;
  theme: DefaultTheme;
}): string => {
  if (chargeState === "CHARGING" || chargeState === "COMPLETE") {
    return theme.colors.success[300];
  }
  return theme.colors.background[500];
};
